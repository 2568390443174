import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { validatePage } from ".";
import { useForm } from "react-hook-form";
import messages from "./messages";
import { withDataFetching } from "src/components/index";
import { AdminScreenBase } from "src/components/admin/components";
import FormData from "form-data";
import { withHandleResponse } from "src/components/index";
import { Notification } from "src/components/helpers/notification";
import { useNavigate } from "react-router-dom";
import axiosInstance from "src/http-common";
import { categories } from "src/const";

interface CreateProductProps {
  data: any[] | null;
  intl: any;
  handleResponse: (response: any) => any;
}

interface FormProps {
  name: {
    "en-gb": string;
    lv: string;
    ru: string;
  };
  description: {
    "en-gb": string;
    lv: string;
    ru: string;
  };
  category: string;
  discount: number;
  price: number;
  stock: number;
  glob: string;
  images: FileList; // Change to FileList to allow multiple files
}

const CreateProduct: React.FC<CreateProductProps> = ({
  data,
  intl,
  handleResponse,
}) => {
  const { register, handleSubmit } = useForm<FormProps>();
  const [files, setFiles] = useState([]) as any;
  const navigate = useNavigate();

  const handleFileChange = (event: any) => {
    setFiles(event.target.files);
  };

  const locales = ["en-gb", "lv", "ru"];

  const onSubmit = (data: FormProps) => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("images", files[i]);
    }

    formData.append("name", JSON.stringify(data.name));
    formData.append("description", JSON.stringify(data.description));
    formData.append("category", data.category);
    formData.append("price", data.price);
    formData.append("stock", data.stock);
    formData.append("glob", data.glob);
    formData.append("characteristics", JSON.stringify([]));
    formData.append("discount", data.discount);

    axiosInstance
      .request({
        method: "POST",
        url: "/adminka/new-product",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      })
      .then((response) => {
        const responseData = handleResponse(response);

        if (responseData) {
          Notification(
            intl.formatMessage(messages.notifications.success),
            "success"
          );

          navigate("/adminka/products");
        }
      })
      .catch((e) => {});
  };

  return (
    <AdminScreenBase title={intl.formatMessage(messages.screen.title)}>
      <form onSubmit={handleSubmit(onSubmit)} className="form mx-0">
        {/* ... (previous form elements) */}
        <div className="name-box">
          <div className="name-title">
            {intl.formatMessage(messages.form.name)}
          </div>
          <div className="name-inputs">
            {locales.map((locale, i) => (
              <input
                className="form-input px-2 py-3"
                {...register(`name.${locale}` as any)}
                type="text"
                name={`name.${locale}`}
                id={locale}
                placeholder={locale}
              />
            ))}

            <input
              className="form-input px-2 py-3 p-2"
              type="text"
              id="glob"
              min={0}
              max={48}
              placeholder={intl.formatMessage(messages.form.price)}
              {...register("glob")}
            />
          </div>
          <div className="description-inputs">
            <div className="name-title">
              {intl.formatMessage(messages.form.description)}
            </div>

            {locales.map((locale, i) => (
              <input
                className="form-input px-2 py-3 p-2"
                {...register(`description.${locale}` as any)}
                type="text"
                name={`description.${locale}`}
                id={locale}
                placeholder={locale}
              />
            ))}
          </div>
        </div>
        <div className="name-box">
          <div className="name-title">
            {intl.formatMessage(messages.form.category)}
          </div>
          <select
            className="form-input px-2 py-3"
            id="category"
            {...register("category")}
          >
            {categories.map((category) => (
              <option value={category.name}>{category.name}</option>
            ))}
          </select>
        </div>
        <div className="name-box">
          <div className="name-title">
            {intl.formatMessage(messages.form.price)}
          </div>

          <input
            className="form-input px-2 py-3 p-2"
            type="number"
            id="price"
            min={0}
            placeholder={intl.formatMessage(messages.form.price)}
            {...register("price")}
          />
          <input
            className="form-input px-2 py-3 p-2"
            type="number"
            id="discount"
            placeholder={intl.formatMessage(messages.form.discount)}
            {...register("discount")}
          />
          <input
            className="form-input px-2 py-3"
            type="number"
            id="stock"
            max={100}
            min={0}
            placeholder={intl.formatMessage(messages.form.stock)}
            {...register("stock")}
          />
        </div>
        <div className="name-box">
          <div className="name-title">
            {intl.formatMessage(messages.form.image)}
          </div>
          <input
            className="form-input px-2 py-3"
            type="file"
            id="images"
            name="images"
            accept="image/*"
            onChange={handleFileChange}
            multiple // Allow multiple files
          />
        </div>

        <input
          className="form-submit"
          type="submit"
          value={intl.formatMessage(messages.form.submit)}
        />
      </form>
    </AdminScreenBase>
  );
};

const ComponentWithHandleResponse = withHandleResponse(CreateProduct);

const Component = withDataFetching(
  validatePage,
  "admin"
)(injectIntl(ComponentWithHandleResponse));

export default Component;
