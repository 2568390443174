import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import messages from "src/globalMessages";

interface Props {
  item: any;
  intl: any;
  options: {
    onDelete: (itemId: string) => void;
    onSave: (itemId: string, quantity: number) => void;
    onChange: (itemId: string, quantity: number) => void;
  };
}

const CartItem: React.FC<Props> = ({
  item,
  intl,
  options: { onDelete, onSave, onChange },
}) => {
  const { name, price, quantity, cartItemId, itemId } = item;

  const [count, setCount] = useState<number>(quantity ?? 1);
  const [ogQuantity, setOgQuantity] = useState<number>(quantity ?? 1);
  const navigate = useNavigate();

  const handleQuantityChange = (newCount: number) => {
    if(newCount < 0) return;

    if(newCount > ogQuantity) {
      return
    }

    setCount(newCount);
    onChange(itemId, newCount);
  };

  return (
    <div className="w-full md:w-full flex flex-grow justify-between flex-row my-4">
      <div className="flex flex-row">
        <div className="flex !w-20 !h-20 bg-accent"></div>
        <div className="text-accent flex flex-col font-semibold text-lg mx-3">
          <a
            href={`/product/${item.glob}`}
            className="font-semibold cursor-pointer w-36 md:w-32 no-underline hover:underline !text-accent text-lg"
          >
            {name["lv"]}
          </a>
          <div className="text-accent font-normal text-base">
            {intl.formatMessage(messages.text.price_format, {
              price: price.toFixed(2),
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center nd:items-start justify-center md:w-48 xs:mx-2 nd:!mx-0">
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-row">
            <div
              className="item-banner-crease !bg-first !border-1 !border-accent !border-opacity-30 rounded-l-full !hidden nd:!flex"
              onClick={() => handleQuantityChange(count - 1)}
            >
              -
            </div>
            <input
              type="text"
              value={count}
              className="count-input !px-1 !border rounded-lg nd:!border-0 nd:rounded-none nd:!border-y !w-14 sm:w-16 !border-accent !border-opacity-30 !bg-first !text-accent text-base"
              onChange={(event) => {
                const newCount = Number(event.target.value);
                if (newCount >= 0 && ogQuantity >= newCount) {
                  handleQuantityChange(newCount);
                }
              }}
            />
            <div
              className="item-banner-crease !hidden nd:!flex !bg-first !border-1 !border-accent !border-opacity-30 rounded-r-full"
              onClick={() => handleQuantityChange(count + 1)}
            >
              +
            </div>
          </div>
        </div>
        <div
          className="text-second font-medium mt-2 nd:ml-8 underline cursor-pointer"
          onClick={() => onDelete(itemId)}
        >
          Remove
        </div>
      </div>
      <div className="text-accent flex items-center hidden md:flex w-16">
        {intl.formatMessage(messages.text.price_format, {
          price: (count * price).toFixed(2),
        })}
      </div>
    </div>
  );
};

export default React.memo(injectIntl(CartItem));
