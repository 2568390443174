import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../PaymentDetails/PaymentDetails";
import { loadStripe } from "@stripe/stripe-js";

import {
  LoadingScreen,
  withDataFetching,
  withHandleResponse,
} from "src/components/index";
import { injectIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "src/http-common";

const getStripe = async () => {
  const response = await axiosInstance.get("/config", {
    withCredentials: true,
  });

  return response;
};

interface PaymentProps {
  intl: any;
  data: any | null;
  handleResponse: (response: any) => AxiosResponse;
}

const Payment: React.FC<PaymentProps> = ({ intl, data, handleResponse }) => {
  const [clientSecret, setClientSecret] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const stripePromise = loadStripe(data.publicKey);

  useEffect(() => {
    axiosInstance
      .post("/create-payment-intent", {
        orderId: state?.orderId,
      })
      .then((response) => {
        setClientSecret(response.data.clientSecret);
      })
      .catch((error) => {
        navigate("/404");
      });
  }, []);

  if (!clientSecret || !stripePromise) return <LoadingScreen />;

  return (
    <>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm />
        </Elements>
      )}
    </>
  );
};

const InjectedComponent = withHandleResponse(Payment);
export default withDataFetching(getStripe)(injectIntl(InjectedComponent));
