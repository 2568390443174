import React from "react";
import { Edit, Trash } from "react-feather";
import Link from "../../Link";
import { useAppContext } from "../../LanguageContext";
import ActionButton from "../../ActionButton";
import axiosInstance from "src/http-common";
import withHandleResponse from "../../withHandleResponse";
import { Notification } from "../../helpers/notification";
import { AxiosResponse } from "axios";
import { injectIntl } from "react-intl";
import messages from "../../../globalMessages";
interface ItemBannerProps {
  item: {
    name: {
      [key: string]: string;
    };
    description: {
      [key: string]: string;
    };
    image: string;
    price: string;
    _id: string;
    stock: number;
  };
  handleResponse: (response: any) => AxiosResponse;
  intl: any;
}

const ItemBanner: React.FC<ItemBannerProps> = ({
  item,
  handleResponse,
  intl,
}) => {
  const { state } = useAppContext();
  const { language } = state;
  const { name, description, image, price, _id, stock } = item;

  const handleDelete = async (id: string) => {
    const response = await axiosInstance.delete(`/products/${_id}`, {
      data: { id },
      withCredentials: true,
    });

    const responseData = handleResponse(response);

    if (responseData) {
      Notification(
        intl.formatMessage(messages.notifications.delete_success),
        "success"
      );

      window.location.reload();
    }
  };

  return (
    <div className="item-banner">
      <div className="item-banner-inner">
        <div className="item-banner-image">
          <img src={image} alt={name[language] ?? name["en-gb"]} />
        </div>

        <div className="item-banner-title">
          {name[language] ?? name["en-gb"]}
        </div>
        <div className="item-banner-price">{price}</div>
        <div className="item-banner-actions">
          <Link
            title="edit"
            navTo={`/adminka/product/${_id}`}
            icon={<Edit />}
            state={{
              _id,
              name,
              description,
              image,
              price,
              stock,
            }}
          />
          <ActionButton
            title={intl.formatMessage(messages.buttons.delete)}
            icon={<Trash />}
            onClick={() => handleDelete(_id)}
          />
        </div>
      </div>
    </div>
  );
};

export default withHandleResponse(injectIntl(ItemBanner));
