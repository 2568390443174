import axiosInstance from "src/http-common";

interface CartItemUpdate {
  itemId: string;
  quantity: number;
}

export const getCartItems = async () => {
  const response = await axiosInstance.get("/cart", {
    withCredentials: true,
  });
  return response;
};

export const onSave = async (items: CartItemUpdate[]) => {
  const response = await axiosInstance.put(
    "/cart",
    { items, action: "updateCount" },
    { withCredentials: true }
  );

  return response;
};

export const onDelete = async (itemId: string) => {
  const response = await axiosInstance.put(
    `/cart`,
    { itemId, action: "deleteItem" },
    {
      withCredentials: true,
    }
  );
  return response;
};
