import { adminRedirect } from "src/components/admin/helpers/adminRedirect";
import axiosInstance from "src/http-common";

export const login = (email: string, password: string, navigate: any) => {
  axiosInstance
    .post("/adminka", { email, password }, { withCredentials: true })
    .then((response) => {
      const { redirect } = response.data;
      return adminRedirect(redirect, navigate);
    });
};

export const validate = async () => {
  const response = await axiosInstance.get("/adminka", {
    withCredentials: true,
  });

  return response;
};
