import { defineMessages } from "react-intl";

const messages = {
  navigation: defineMessages({
    home: {
      id: "navigation.home",
      defaultMessage: "Home",
    },
    about: {
      id: "navigation.about",
      defaultMessage: "About",
    },
    contact: {
      id: "navigation.contact",
      defaultMessage: "Contact",
    },
    cart: {
      id: "navigation.cart",
      defaultMessage: "Cart",
    },
    shop: {
      id: "navigation.shop",
      defaultMessage: "Shop",
    },
    languages: {
      id: "navigation.languages",
      defaultMessage: "Languages",
    },
    english: {
      id: "navigation.english",
      defaultMessage: "English",
    },
    latvian: {
      id: "navigation.latvian",
      defaultMessage: "Latvian",
    },
    russian: {
      id: "navigation.russian",
      defaultMessage: "Russian",
    },
  }),

  title: defineMessages({
    loading: {
      id: "title.loading",
      defaultMessage: "Loading...",
    },
  }),

  text: defineMessages({
    unexpected_error: {
      id: "text.unexpected_error",
      defaultMessage: "An unexpected error occurred",
    },
    reviews: {
      id: "text.reviews",
      defaultMessage: "({reviews})",
    },
    price_format: {
      id: "text.price_format",
      defaultMessage: "€{price}",
    },
    discount: {
      id: "text.discount",
      defaultMessage: "{discount}% off",
    },
    stock: {
      id: "text.stock",
      defaultMessage: "Stock: {stock}",
    },
    quantity: {
      id: "text.quantity",
      defaultMessage: "Quantity: {quantity}",
    },

    review: {
      id: "text.review",
      defaultMessage: "Review",
    },

    name: {
      id: "text.name",
      defaultMessage: "Name",
    },

    email: {
      id: "text.email",
      defaultMessage: "Email",
    },

    rating: {
      id: "text.rating",
      defaultMessage: "Rating",
    },
  }),

  buttons: defineMessages({
    save: {
      id: "buttons.save",
      defaultMessage: "Save",
    },
    switches: {
      id: "buttons.switches",
      defaultMessage: "Switches",
    },
    keycaps: {
      id: "buttons.keycaps",
      defaultMessage: "Keycaps",
    },
    tools: {
      id: "buttons.tools",
      defaultMessage: "Tools",
    },
    delete: {
      id: "buttons.delete",
      defaultMessage: "Delete",
    },
    home: {
      id: "buttons.home",
      defaultMessage: "Home",
    },
    shop: {
      id: "buttons.shop",
      defaultMessage: "Shop",
    },
  }),

  error: defineMessages({
    title: {
      id: "error.title",
      defaultMessage: "Error",
    },
    network_error: {
      id: "error.network_error",
      defaultMessage: "Network Error",
    },
    product_not_found: {
      id: "error.product_not_found",
      defaultMessage: "Product not found",
    },
    product_already_reviewed: {
      id: "error.product_already_reviewed",
      defaultMessage: "You have already reviewed this product",
    },
    all_fields_required: {
      id: "error.all_fields_required",
      defaultMessage: "All fields are required",
    },
    error_creating_cart: {
      id: "error.error_creating_cart",
      defaultMessage: "Error creating cart",
    },
    product_bad_request: {
      id: "error.product_bad_request",
      defaultMessage: "Bad Request: Missing product ID",
    },
    must_be_at_least_one: {
      id: "error.must_be_at_least_one",
      defaultMessage: "Quantity must be at least 1",
    },
    no_items_in_stock: {
      id: "error.no_items_in_stock",
      defaultMessage: "Not enough items in stock",
    },
    cart_not_found: {
      id: "error.cart_not_found",
      defaultMessage: "Cart not found",
    },
    internal_server_error: {
      id: "error.internal_server_error",
      defaultMessage: "Server error",
    },
    discount_must_be_number: {
      id: "error.discount_must_be_number",
      defaultMessage: "Discount must be a number",
    },
    stock_must_be_real: {
      id: "error.stock_must_be_real",
      defaultMessage: "Stock must be a positive number",
    },
    price_must_be_a_number: {
      id: "error.price_must_be_a_number",
      defaultMessage: "Price must be a positive number",
    },
    cannot_create_product: {
      id: "error.cannot_create_product",
      defaultMessage: "Error creating product",
    },
    process_error: {
      id: "error.process_error",
      defaultMessage: "Process error",
    },
    email_and_password_required: {
      id: "error.email_and_password_required",
      defaultMessage: "Please provide email and password",
    },
    invalid_credentials: {
      id: "error.invalid_credentials",
      defaultMessage: "Invalid credentials",
    },
    cart_is_empty: {
      id: "error.cart_is_empty",
      defaultMessage: "Cart is empty",
    },
    item_not_found: {
      id: "error.item_not_found",
      defaultMessage: "Item not found",
    },
    increase_quantity: {
      id: "error.increase_quantity",
      defaultMessage: "Cannot increase quantity of item in cart",
    },
    admin_token_required: {
      id: "error.admin_token_required",
      defaultMessage: "Admin token missing",
    },
    invalid_token: {
      id: "error.invalid_token",
      defaultMessage: "Invalid token",
    },
    user_is_not_admin: {
      id: "error.user_is_not_admin",
      defaultMessage: "User is not an admin",
    },
    cannot_fetch_user_data: {
      id: "error.cannot_fetch_user_data",
      defaultMessage: "Internal Server Error: Unable to fetch user data",
    },
  }),
  notifications: defineMessages({
    added_to_cart: {
      id: "notifications.added_to_cart",
      defaultMessage: "Added to cart",
    },
    delete_success: {
      id: "notifications.delete_success",
      defaultMessage: "Deleted successfully",
    },
  }),
};

export default messages;
