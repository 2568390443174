import { defineMessage, defineMessages } from "react-intl";
import globalMessages from "src/globalMessages";
import { merge } from "lodash";

const messages = {
  cart_page: defineMessages({
    title: {
      id: "cart_page.title",
      defaultMessage: "My cart",
    },
  }),

  table: defineMessages({
    discount: {
      id: "cart_page.table.discount",
      defaultMessage: "Discount",
    },

    total_after_discount: {
      id: "cart_page.table.total_after_discount",
      defaultMessage: "Total after discount",
    },

    total_before_discount: {
      id: "cart_page.table.total_before_discount",
      defaultMessage: "Total before discount",
    },
  }),

  buttons: defineMessages({
    checkout: {
      id: "cart_page.buttons.checkout",
      defaultMessage: "Checkout",
    },
  }),

  no_items_in_cart: defineMessage({
    id: "cart_page.no_items_in_cart",
    defaultMessage: "Your cart currently has no items in it",
  }),
  notifications: defineMessages({
    removed_from_cart: {
      id: "cart_page.notifications.removed_from_cart",
      defaultMessage: "Removed from cart",
    },
    saved_cart: {
      id: "cart_page.notifications.saved_cart",
      defaultMessage: "Saved cart",
    },
  }),
};

export default merge({}, globalMessages, messages);
