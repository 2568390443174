import axiosInstance from "src/http-common";
const fetchCartCount = async () => {
  try {
    const response = await axiosInstance.get("/cart/count", {
      withCredentials: true,
    });
    return response;
  } catch (e: any) {
    return e.response;
  }
};

export default fetchCartCount;
