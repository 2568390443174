import React from "react";
import ScreenBase from "./AdminScreenBase";
import { injectIntl } from "react-intl";
import messages from "../../../globalMessages";

interface LoadingScreenProps {
  intl: any;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ intl }) => {
  return (
    <ScreenBase title={intl.formatMessage(messages.title.loading)}>
      <div className="loadingContainer">
        <span className="loader"></span>
      </div>
    </ScreenBase>
  );
};

export default injectIntl(LoadingScreen);
