import React, { useEffect } from "react";
import ScreenBase from "./ScreenBase";
import { injectIntl } from "react-intl";
import messages from "../globalMessages";

interface LoadingScreenProps {
  intl: any;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ intl }) => {

  return (
    <ScreenBase title="Keebstore">
      <div className="loadingContainer">
        <span className="loader !border-second !border-opacity-55"></span>
      </div>
    </ScreenBase>
  );
};

export default injectIntl(LoadingScreen);
