import { toLower } from "lodash";
import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from "react";

interface AppState {
  language: string;
  dropdownOpen: boolean;
  loading: boolean;
  cartOverlayVisible: boolean;
}

interface AppContextProps {
  state: AppState;
  dispatch: Dispatch<any>; // You can replace 'any' with your specific action type
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

function appReducer(state: AppState, action: any) {
  switch (action.type) {
    case "SET_LANGUAGE":
      return { ...state, language: action.payload };
    case "TOGGLE_DROPDOWN":
      return { ...state, dropdownOpen: !state.dropdownOpen };
    default:
      return state;
  }
}

const initialState: AppState = {
  language: toLower(navigator.language) || "en-gb",
  dropdownOpen: false,
  loading: false,
  cartOverlayVisible: false,
};

function AppProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
}

export { AppProvider, useAppContext };
