import React from "react";
import { injectIntl } from "react-intl";

import { fetchData } from ".";
import {
  AdminItemBanner,
  AdminScreenBase,
} from "src/components/admin/components";
import { ItemList, withDataFetching } from "src/components/index";

interface ProductsProps {
  data: any[] | null;
  intl: any;
}

const Products: React.FC<ProductsProps> = ({ data, intl }) => {
  return (
    <AdminScreenBase title="Products">
      <ItemList items={data} Component={AdminItemBanner} />
    </AdminScreenBase>
  );
};

const ComponentWithData = withDataFetching(
  fetchData,
  "admin"
)(injectIntl(Products));
export default ComponentWithData;
