import React, { useEffect, useMemo } from "react";
import { AxiosResponse } from "axios";
import { injectIntl } from "react-intl";
import {
  ScreenBase,
  withDataFetching,
  ActionButton,
  Map as GoogleMap,
  SearchableDropdown,
  withHandleResponse,
} from "src/components/index";
import messages from "./messages";
import { confirmCheckout, getCheckoutItems } from ".";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { Check, ChevronDown, ChevronUp, CreditCard } from "react-feather";

interface Props {
  intl: any;
  data: any | null;
  handleResponse: (response: any) => AxiosResponse;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  station: string | null;
}

const ConfirmCheckout: React.FC<Props> = ({ intl, data, handleResponse }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormValues>();

  const { totalCost: total } = data;
  const [mapData, setMapData] = React.useState<any>([]);
  const [extendedDetails, setExtendedDetails] = React.useState<any>([]);
  const { state} = useLocation();

  console.log(state, "SJAIEFHISDHFISUHDGIUHSFGIUEH")
  const language = intl.locale;
  const navigate = useNavigate();

  const country = watch("country");
  const selectedStation = watch("station");

  const abbreviations: any = {
    latvia: "LV",
    lithuania: "LT",
    estonia: "EE",
  };

  const filteredMarkers = useMemo(() => {
    return data?.mapData?.filter((point: any) => {
      return point?.A0_NAME === abbreviations[country?.toLowerCase()];
    });
  }, [data, country]);

  useEffect(() => {
    console.log(extendedDetails);
  }, [extendedDetails]);

  useEffect(() => {
    console.log("data retrigger");
    setMapData(filteredMarkers);
    console.log(filteredMarkers, "markers", data.mapData);
  }, [filteredMarkers]);

  if (data?.items?.length === 0) {
    console.log("BAILOUT");
    navigate("/404");
    return null;
  }

  console.log(total);

  const squishedItems = Object.values(
    data?.items?.reduce((acc: any, item: any) => {
      if (!acc[item.productId]) {
        acc[item.productId] = { ...item, quantity: 0 };
      }
      acc[item.productId].quantity += item.quantity;
      return acc;
    }, {})
  );

  console.log(total.shipping, total.afterDiscount, total.beforeDiscount);

  console.log(data, squishedItems);

  console.log(squishedItems)

  return (
    <ScreenBase title={intl.formatMessage(messages.confirm_checkout.title)}>
      <div className="checkout-container !bg-first">
        <div className="form-container !bg-first flex xs:flex-col  lg:!flex-row-reverse	">
          <div className="order-summary-container !mx-0 flex flex-col flex-grow xs:w-full lg:w-5/6 xs:px-8 sm:px-24 md:px-36 lg:px-8 py-3 lg:sticky lg:top-0 lg:self-stretch lg:max-h-screen border-b lg:border-b-0 lg:!border-l border-accent !border-opacity-15">
            <div className="flex flex-row !items-center py-2 justify-between">
              <div
                onClick={() => setExtendedDetails(!extendedDetails)}
                className="flex flex-row subsection-title w-fit lg:mt-1 xs:text-base xs:font-medium lg:font-normal lg:text-lg !text-second xs:cursor-pointer lg:cursor-text lg:!text-accent uppercase opacity-65 font-normal"
              >
                <h2 className="subsection-title w-fit lg:mt-1 xs:mb-0 lg:mb-2 xs:text-base xs:font-medium lg:font-normal lg:text-lg !text-second xs:cursor-pointer lg:cursor-text lg:!text-accent uppercase font-normal">
                  show Order summary
                </h2>
                <div className="xs:flex lg:hidden self-center mx-1">
                  {extendedDetails ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </div>
              </div>

              <p className="text-accent text-lg self-center font-bold">
                {total.beforeDiscount.toFixed(2)}€
              </p>
            </div>
            <div
              className={`xs:overflow-hidden xs:transition-max-height xs:duration-500 xs:ease-in-out ${
                extendedDetails ? "xs:max-h-screen" : "xs:max-h-0"
              } lg:overflow-y-auto lg:overflow-x-hidden lg:transition-none lg:max-h-screen`}
            >
              {squishedItems.map((item: any) => (
                <div
                  key={item.id}
                  className="flex flex-row items-center h-fit !text-first bg-first !mx-0 border-1 rounded-sm border-first xs:w-full my-4"
                >
                  <div className="relative">
                    <img
                      className="h-16 rounded-md"
                      src="https://dummyimage.com/400x400/000/fff"
                      alt={item?.name[language] ?? item?.name["en-gb"]}
                    />
                    <div className="absolute -top-2 -right-2 bg-second text-white text-xs font-bold h-6 w-6 flex items-center justify-center rounded-full">
                      {item.quantity}
                    </div>
                  </div>

                  <div className="flex flex-col flex-grow sm:flex-row ml-4 justify-between ">
                    <h3 className="flex  !text-accent !text-base mx-0 my-0 sm:mx-4">
                      {item.name[language] ?? item.name["en-gb"]}
                    </h3>
                    <div className="flex sm:justify-end">
                      <p className="text-accent text-base">
                        {(
                          item.total.price *
                          (1 - item.total.discount / 100)
                        ).toFixed(2)}
                        €
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <div className="flex flex-row !w-full rounded-lg mb-8">
                <div className="flex flex-col flex-grow">
                  <div className="text-accent text-lg opacity-75">Subtotal</div>
                  <div className="text-accent text-lg my-1 opacity-75">
                    Discount
                  </div>

                  <div className="text-accent text-lg my-1 opacity-75">
                    Shipping
                  </div>

                  <div className="text-accent text-2xl font-bold mt-1 mb-2">
                    Total
                  </div>

                  <div className="text-accent text-base opacity-75">
                    Tax included
                  </div>
                </div>

                <div className="flex flex-col flex-grow !items-end">
                  <div className="text-accent text-lg opacity-75">
                    {total.beforeDiscount.toFixed(2)}€
                  </div>

                  <div className="text-accent text-lg my-1 opacity-75">
                    {(total.beforeDiscount - total.afterDiscount).toFixed(2)}€
                  </div>

                  <div className="text-accent text-lg my-1 opacity-75">
                    {total.shipping.toFixed(2)}€
                  </div>

                  <div className="text-accent xs:text-xl lg:text-2xl mt-1 mb-2 font-bold">
                    {(total.afterDiscount + total.shipping).toFixed(2)}€
                  </div>
                </div>
              </div>
            </div>

            <div className="hidden lg:flex lg:px-6 lg:flex-col lg:flex-grow">
              <div
                onClick={handleSubmit((data) => {
                  try {
                    confirmCheckout(data, state).then((response) => {
                      const data = handleResponse(response);
                      navigate("/payment", {
                        state: { orderId: response.data?.orderId },
                      });
                    });
                  } catch (e) {}
                })}
                className="flex flex-row cursor-pointer rounded-lg bg-second text-first text-base items-center font-semibold text-center justify-center py-3 mt-8"
              >
                <CreditCard className="mr-2" />
                Checkout
              </div>
            </div>
            {/* <div className=" flex flex-grow border-b border-accent !border-opacity-25 lg:hidden"></div> */}
          </div>
          <div className="flex flex-col sm:flex-col flex-grow w-full xs:px-8 sm:px-24 md:px-36 lg:px-14 pt-6 !bg-white">
            <div className="flex flex-col w-full">
              <div className="flex flex-col">
                <h2 className="subsection-title  mt-3 !text-lg !text-accent uppercase opacity-65 font-normal">
                  {intl.formatMessage(messages.text.personal_information)}
                </h2>
              </div>
              <div className="flex flex-grow w-full xs:!flex-col nd:!flex-row">
                <div className="input-container  nd:pr-2">
                  <label
                    htmlFor="firstName"
                    className="section-subtitle opacity-75 !text-accent"
                  >
                    {intl.formatMessage(messages.labels.first_name)}
                  </label>
                  <input
                    type="text"
                    className="regular-input !w-full !text-accent border-1 border-accent rounded-md border-opacity-25"
                    placeholder={intl.formatMessage(messages.inputs.first_name)}
                    {...register("firstName", {
                      required: intl.formatMessage(
                        messages.error.first_name_required
                      ),
                      maxLength: {
                        value: 20,
                        message: intl.formatMessage(
                          messages.error.first_name_length
                        ),
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="firstName"
                    render={({ message }) => {
                      return (
                        message && <p className="error-message">{message}</p>
                      );
                    }}
                  />
                </div>
                <div className="input-container md:!pl-2">
                  <label
                    htmlFor="lastName"
                    className="section-subtitle opacity-75 !text-accent"
                  >
                    {intl.formatMessage(messages.labels.last_name)}
                  </label>
                  <input
                    type="text"
                    className="regular-input !w-full !text-accent border-1 border-accent rounded-md border-opacity-25"
                    placeholder={intl.formatMessage(messages.inputs.last_name)}
                    {...register("lastName", {
                      required: intl.formatMessage(
                        messages.error.last_name_required
                      ),
                      maxLength: {
                        value: 20,
                        message: intl.formatMessage(
                          messages.error.last_name_length
                        ),
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="lastName"
                    render={({ message }) => {
                      return (
                        message && <p className="error-message">{message}</p>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="input-container">
                <label
                  htmlFor="email"
                  className="section-subtitle opacity-75 !text-accent"
                >
                  {intl.formatMessage(messages.labels.email)}
                </label>
                <input
                  type="email"
                  className="regular-input !w-full !text-accent border-1 border-accent rounded-md border-opacity-25"
                  placeholder={intl.formatMessage(messages.inputs.email)}
                  {...register("email", {
                    required: intl.formatMessage(messages.error.email_required),
                    validate: (value) =>
                      /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ||
                      intl.formatMessage(messages.error.email_invalid),
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => {
                    return (
                      message && <p className="error-message">{message}</p>
                    );
                  }}
                />
              </div>
              <div className="input-container">
                <label
                  htmlFor="phone"
                  className="section-subtitle opacity-75 !text-accent"
                >
                  {intl.formatMessage(messages.labels.phone)}
                </label>
                <input
                  className="regular-input !w-full !text-accent border-1 border-accent rounded-md border-opacity-25"
                  type="phone"
                  placeholder={intl.formatMessage(messages.inputs.phone)}
                  {...register("phone", {
                    required: intl.formatMessage(messages.error.phone_required),
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ message }) => {
                    return (
                      message && <p className="error-message">{message}</p>
                    );
                  }}
                />
              </div>
            </div>
            <div className="flex flex-grow flex-col w-full">
              <h2 className="subsection-title mt-3 !text-lg !text-accent uppercase opacity-65 font-normal">
                {intl.formatMessage(messages.text.shipping_information)}
              </h2>
              <label className="section-subtitle opacity-75 !text-accent">
                {intl.formatMessage(messages.text.country)}
              </label>
              <select
                className="dropdown-selector !w-full !bg-white font-normal !text-accent !border-1 !border-accent !border-opacity-25"
                {...register("country", {
                  required: intl.formatMessage(messages.error.country_required),
                  validate: (value) =>
                    ["Latvia", "Lithuania", "Estonia"].includes(value) ||
                    intl.formatMessage(messages.error.country_invalid),
                })}
              >
                <option value="Latvia">
                  {intl.formatMessage(messages.countries.latvia)}
                </option>
                <option value="Lithuania">
                  {intl.formatMessage(messages.countries.lithuania)}
                </option>
                <option value="Estonia">
                  {intl.formatMessage(messages.countries.estonia)}
                </option>
              </select>
              <ErrorMessage
                errors={errors}
                name="country"
                render={({ message }) => {
                  return message && <p className="error-message">{message}</p>;
                }}
              />
              <label className="section-subtitle opacity-75 !text-accent">
                {intl.formatMessage(messages.text.parcel_container_location)}
              </label>
              <SearchableDropdown
                options={mapData}
                selectedVal={selectedStation}
                handleChange={(selectedValue: string | null) => {
                  return setValue("station", selectedValue);
                }}
              />
              <div className="lg:hidden">
                <div
                  onClick={handleSubmit((data) => {
                    try {
                      confirmCheckout(data, state).then((response) => {
                        const data = handleResponse(response);
                        navigate("/payment", {
                          state: { orderId: response.data?.orderId },
                        });
                      });
                    } catch (e) {}
                  })}
                  className="flex flex-row cursor-pointer rounded-lg bg-second text-first text-base items-center font-semibold text-center justify-center py-3 mt-8"
                >
                  <CreditCard className="mr-2" />
                  Checkout
                </div>
              </div>
              <GoogleMap
                data={mapData}
                selectedPoint={mapData?.find((point: any) => {
                  return point?.NAME === selectedStation ?? null;
                })}
                onSelect={(marker: string) => setValue("station", marker)}
                country={country}
              />
            </div>
          </div>
        </div>
      </div>
    </ScreenBase>
  );
};

const ConfirmCheckoutWithHandleResponse = withHandleResponse(ConfirmCheckout);
const ComponentWithData = withDataFetching(async (state) => {
  console.log(state, "STATE");
  return await getCheckoutItems(state);
})(
  injectIntl(ConfirmCheckoutWithHandleResponse)
);

export default ComponentWithData;
