// src/ReviewCarousel.js

import React, { useState, useEffect } from "react";
import ReactStars from "react-stars";

interface Review {
  name: string;
  rating: number;
  comment: string;
}

interface ReviewCarouselProps {
  reviews: Review[];
}

const ReviewCarousel: React.FC<ReviewCarouselProps> = ({ reviews }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
        setIsAnimating(false);
      }, 1000); // Match the CSS animation duration
    }, 7000); // Change review every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [reviews.length]);

  return (
    <div className="carousel">
      <div
        className={`review flex flex-grow flex-col border border-accent h-max border-opacity-35 rounded-lg px-2 py-2 my-4 ${
          isAnimating ? "slide-out" : "slide-in"
        }`}
      >
        <div className="flex mb-3">
          <h3 className="text-accent  !text-lg">{reviews[currentIndex].name}</h3>
          <p className="text-accent !text-lg mx-3">
            <ReactStars
              className="product-rating-stars !cursor-pointer !px-0"
              count={5}
              edit={false}
              value={reviews[currentIndex].rating

              }
              size={16}
              color2={"#e89b27"}
            />
          </p>
        </div>

        <p className="text-accent line-clamp-1 text-lg">{reviews[currentIndex].comment}</p>
      </div>
    </div>
  );
};

export default ReviewCarousel;
