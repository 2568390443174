import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import withHandleResponse from "src/components/withHandleResponse";
import { injectIntl } from "react-intl";
import messages from "./messages";
import axiosInstance from "src/http-common";

interface PaymentDetails {
  handleResponse: (response: any) => AxiosResponse;
  intl: any;
}
const CheckoutForm: React.FC<PaymentDetails> = ({ handleResponse, intl }) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const {
    state: { orderId },
  } = location;

  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { paymentIntent, error }: any = await stripe.confirmPayment({
      elements,
      redirect: "if_required",
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsProcessing(false);
    } else if (paymentIntent.status === "succeeded") {
      // Payment succeeded, save the order or perform other actions

      const response = await axiosInstance.put("/checkout", {
        language: intl.locale,
        paymentIntentId: paymentIntent.id,
        orderId: orderId,
      });

      const data = handleResponse(response);

      setMessage("Payment succeeded! Order saved.");
      navigate("/completion", { state: { email: response.data?.email } });
      setIsProcessing(false);
    }

    setIsProcessing(false);
  };

  return (
    <form
      id="payment-form"
      className="payment-form"
      onSubmit={handleSubmit}
      style={{ display: "flex", flex: 1 }}
    >
      <PaymentElement id="payment-element" />
      <button
        disabled={isProcessing || !stripe || !elements}
        id="submit"
        style={{
          border: "1px solid #000",
          borderRadius: "5px",
          color: "#000",
          backgroundColor: "#fff",
          boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11)",
          margin: "1rem",
          display: "flex",
          flex: 1,
          padding: "1rem 2rem 1rem 2rem",
        }}
      >
        <span id="button-text">
          {isProcessing
            ? intl.formatMessage(messages.payment_details.processing)
            : intl.formatMessage(messages.payment_details.pay_now)}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};
const injectedComponent = withHandleResponse(CheckoutForm);
export default injectIntl(injectedComponent);
