import React from "react";
import ScreenBase from "./ScreenBase";
import { injectIntl } from "react-intl";
import messages from "../globalMessages";

interface ErrorScreenProps {
  intl: any;
  error: any;
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({ intl, error }) => {
  return (
    <ScreenBase title={intl.formatMessage(messages.error.title)}>
      <div className="error-container relative flex flex-col flex-grow items-center justify-center">
        {/* 404 Text */}
        <h1 className="absolute !select-none inset-0 flex justify-center items-center text-13xl md:text-25xl tracking-wider opacity-10 text-accent font-semibold">
          404
        </h1>

        {/* Error Message and Link */}
        <div className="relative z-10 flex flex-col items-center">
          <h2 className="text-2xl !select-none md:text-4xl text-accent font-semibold mb-8">
            {error}
          </h2>
          <a
            href="/shop/all"
            className="flex !select-none no-underline px-4 lg:px-8 text-base md:text-xl !text-first select-none cursor-pointer border-1 bg-second border-second rounded-full py-3 font-semibold"
          >
            Continue shopping
          </a>
        </div>
      </div>
    </ScreenBase>
  );
};

export default injectIntl(ErrorScreen);
