import globalMessages from "src/globalMessages";
import { defineMessages } from "react-intl";
import merge from "lodash/merge";

const messages = {
  screen: defineMessages({
    title: {
      id: "admin.login.screen.title",
      defaultMessage: "Login",
    },
  }),

  form: defineMessages({
    email: {
      id: "admin.login.form.email",
      defaultMessage: "Email",
    },
    password: {
      id: "admin.login.form.password",
      defaultMessage: "Password",
    },
    submit: {
      id: "admin.login.form.submit",
      defaultMessage: "Submit",
    },
  }),
};

export default merge(globalMessages, messages);
