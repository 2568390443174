const lala = [
    { name: "John Doe", rating: 3, comment: "Good product 29342034923492349-23492-0349-023940-2394-02394-20934-02934-0" },
    { name: "Jane Doe", rating: 5, comment: "Great product" },
    { name: "John Doe", rating: 3, comment: "Good product 29342034923492349-23492-0349-023940-2394-02394-20934-02934-0" },
    { name: "Jane Doe", rating: 5, comment: "Great product" },
    { name: "John Doe", rating: 3, comment: "Goodifdjduifhgiudfhguihdfiughdiuf product 29342034923492349-23492-0349-023940-2394-02394-20934-02934-0" },
    { name: "Jane Doe", rating: 5, comment: "Great product" },
    { name: "Alice Smith", rating: 4, comment: "Very useful" },
    { name: "Bob Johnson", rating: 2, comment: "Not as expected" },
    { name: "Charlie Brown", rating: 5, comment: "Excellent!" },
    { name: "Dana White", rating: 1, comment: "Poor quality" },
    { name: "Eve Black", rating: 4, comment: "Quite good" },
    { name: "Frank Green", rating: 3, comment: "Average product" },
    { name: "Grace Hall", rating: 4, comment: "I liked it" },
    { name: "Hank Miller", rating: 2, comment: "Could be better" },
    { name: "Ivy Wilson", rating: 5, comment: "Fantastic!" },
    { name: "Jack Lee", rating: 1, comment: "Very disappointed" },
    { name: "Karen Davis", rating: 4, comment: "Nice product" },
    { name: "Leo Clark", rating: 3, comment: "It's okay" },
    { name: "Mia Turner", rating: 5, comment: "Loved it!" },
    { name: "Nick Young", rating: 2, comment: "Not great" },
    { name: "Olivia Hill", rating: 4, comment: "Pretty good" },
    { name: "Paul King", rating: 3, comment: "Meh" },
    { name: "Quinn Scott", rating: 5, comment: "Amazing!" },
    { name: "Rachel Adams", rating: 1, comment: "Terrible product" },
    { name: "Sam Baker", rating: 4, comment: "Good value" },
    { name: "Tina Martin", rating: 3, comment: "Satisfactory" },
    { name: "Uma Carter", rating: 5, comment: "Highly recommend" },
    { name: "Victor Allen", rating: 2, comment: "Not worth it" },
    { name: "Wendy Nelson", rating: 4, comment: "Very nice" },
    { name: "Xander Rogers", rating: 3, comment: "Just fine" },
    { name: "Yara Cook", rating: 5, comment: "Top-notch!" },
    { name: "Zack Bell", rating: 1, comment: "Horrible experience" },
    { name: "Amy Collins", rating: 4, comment: "Good purchase" },
    { name: "Brian Gray", rating: 3, comment: "Decent product" },
    { name: "Cindy Howard", rating: 5, comment: "Outstanding!" },
    { name: "Derek Hughes", rating: 2, comment: "Disappointing" },
    { name: "Ella Reed", rating: 4, comment: "Happy with it" },
    { name: "Fred Ward", rating: 3, comment: "Could be improved" },
    { name: "Gina Fisher", rating: 5, comment: "Exceptional quality" },
    { name: "Holly Stevens", rating: 1, comment: "Very bad" },
];

export { lala };
