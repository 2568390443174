import globalMessages from "src/globalMessages";
import { defineMessages } from "react-intl";
import merge from "lodash/merge";

const messages = {
  screen: defineMessages({
    title: {
      id: "admin.create-product.screen.title",
      defaultMessage: "Create Product",
    },
  }),
  notifications: defineMessages({
    success: {
      id: "admin.create-product.notifications.success",
      defaultMessage: "Successfully created product",
    },
  }),
  form: defineMessages({
    name: {
      id: "admin.create-product.form.name",
      defaultMessage: "Name",
    },
    price: {
      id: "admin.create-product.form.price",
      defaultMessage: "Price",
    },
    category: {
      id: "admin.create-product.form.category",
      defaultMessage: "Category",
    },
    discount: {
      id: "admin.create-product.form.discount",
      defaultMessage: "Discount",
    },
    stock: {
      id: "admin.create-product.form.stock",
      defaultMessage: "Stock",
    },
    description: {
      id: "admin.create-product.form.description",
      defaultMessage: "Description",
    },
    image: {
      id: "admin.create-product.form.image",
      defaultMessage: "Image",
    },
    submit: {
      id: "admin.create-product.form.submit",
      defaultMessage: "Submit",
    },
  }),
};

export default merge(globalMessages, messages);
