import axiosInstance from "src/http-common";

export const getReviews = async (glob: string) => {
  const response = await axiosInstance.get(`/reviews`, {
    params: { glob },
  });

  return response;
};

export const addReview = async (data: any, glob: string) => {
  try {
    const response = await axiosInstance.put(`/reviews`, data, {
      withCredentials: true,
      params: { glob: glob },
    });
    return response;
  } catch (e: any) {
    return e.response;
  }
};
