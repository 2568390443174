import React, { createContext, useContext, useState, useEffect } from "react";
import socketIOClient, { Socket } from "socket.io-client";

const SocketContext = createContext<Socket | null>(null);

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    // Log the current cookies to the console for reference
    var pairs = document.cookie.split(";");

    // Extract the value of the cookie named "cartId"
    const cartId = document.cookie.replace(
      /(?:(?:^|.*;\s*)cartId\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    );

    // Pass the token as query parameters during connection
    try {
      const url =
        process.env.REACT_APP_ENV === "production"
          ? "https://api.keebstore.eu"
          : "http://localhost:8000";
      const newSocket = socketIOClient(url, {
        query: {
          token: cartId,
        },
      });
      setSocket(newSocket);
    } catch (error) {
      console.error("Socket connection error:", error);
    }

    // Cleanup function to close socket on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export const useSocket = () => {
  const socket = useContext(SocketContext);
  if (!socket) {
    return null;
  }
  return socket;
};
