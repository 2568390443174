import React, { useState, useEffect, useRef } from "react";
import { injectIntl } from "react-intl";
import { useAppContext } from "./LanguageContext";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useOverlay } from "./OverlayProvider";

interface LanguageDropdownProps {
  intl: any;
}

function LanguageDropdown({ intl }: LanguageDropdownProps) {
  const { state, dispatch } = useAppContext();
  const { language } = state;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { overlayVisible, toggleOverlay } = useOverlay();

  const changeLanguage = (newLanguage: string) => {
    dispatch({ type: "SET_LANGUAGE", payload: newLanguage });
  };

  const changeLanguageDropdownState = () => {
    dispatch({ type: "TOGGLE_DROPDOWN" });
    // toggleOverlay();
  }

  let active = navigator.language ?? "en-gb";
  let displayLang = "en";
  switch (language) {
    case "en-gb":
      active = "en-gb";
      displayLang = "gb";
      break;
    case "lv":
      active = "lv";
      displayLang = "lv";
      break;
    case "ru":
      active = "ru";
      displayLang = "ru";
      break;
    default:
      active = "en-gb";
      displayLang = "gb";
      break;
  }

  return (
    <div
      className="dropdown my-3 float-right border-opacity-60"
      style={{ float: "left" }}
      ref={dropdownRef}
    >
      <button
        className="dropbtn w-24 h-10"
        onClick={() => changeLanguageDropdownState()}
      >
        <span className={`fi fi-${displayLang} my-custom-flag-class`}></span>
      </button>
    </div>
  );
}

export default injectIntl(LanguageDropdown);
