// src/http-common.ts
import axios from "axios";

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_ENV === "production"
      ? "https://api.keebstore.eu"
      : "http://localhost:8000",
  headers: {
    "Content-type": "application/json",
  },
});

export default axiosInstance;
