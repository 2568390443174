import { defineMessage, defineMessages } from "react-intl";
import globalMessages from "../../globalMessages";
import { merge } from "lodash";

const messages = {
  home: defineMessage({
    id: "home",
    defaultMessage: "Home",
  }),

  text: defineMessages({
    welcome: {
      id: "home.welcome",
      defaultMessage: "Welcome to ",
    },

    keebstore: {
      id: "home.keebstore",
      defaultMessage: "KeebStore",
    },

    intro: {
      id: "home.intro",
      defaultMessage:
        "We provide the best components and accessories for your keyboard:",
    },

    description_two: {
      id: "home.description_two",
      defaultMessage:
        "Discover an array of switches at KeebStore, tailored to your preference - whether you're a gaming pro seeking speed or a typing enthusiast craving tactile feedback. From renowned brands like Cherry, Gateron, and Kailh, we offer only the finest options.",
    },

    description_one: {
      id: "home.description_one",
      defaultMessage:
        "Welcome to KeebStore, your one-stop shop for premium keyboard customization accessories, tailored to elevate your typing experience.      ",
    },

    description_three: {
      id: "home.description_three",
      defaultMessage:
        "At KeebStore, customer satisfaction is paramount. Our dedicated team is here to assist you at every turn, offering expert guidance and tailored recommendations to help you find the perfect accessories for your keyboard. With swift shipping and hassle-free returns, shopping at KeebStore is a seamless experience from start to finish.",
    },
  }),

  buttons: defineMessages({
    go_shopping: {
      id: "home.go_shopping",
      defaultMessage: "Go Shopppping!!",
    },

    all: {
      id: "home.all",
      defaultMessage: "All",
    },
    
    switches: {
      id: "home.switches",
      defaultMessage: "Switches",
    },

    tools: {
      id: "home.tools",
      defaultMessage: "Tools",
    },

    keycaps: {
      id: "home.keycaps",
      defaultMessage: "Keycaps",
    },
  }),
};

export default merge({}, globalMessages, messages);
