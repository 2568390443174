import React from "react";

interface MenuSeparatorProps {
    className?: string;
}

const MenuSeparator: React.FC<MenuSeparatorProps> = ({className}) => {
  return (
    <div className={`menu-separator opacity-25 !w-full md:w-full ${className || ""}`}></div>
);
};

export default MenuSeparator;