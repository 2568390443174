import globalMessages from "src/globalMessages";
import { defineMessages } from "react-intl";
import merge from "lodash/merge";

const messages = {
  panel: defineMessages({
    title: {
      id: "admin.orders.panel.title",
      defaultMessage: "Orders",
    },
  }),
  buttons: defineMessages({
    close: {
      id: "admin.orders.buttons.close",
      defaultMessage: "Close",
    },
  }),

  info: defineMessages({
    no_orders: {
      id: "admin.orders.info.no_orders",
      defaultMessage: "No orders",
    },
  }),

  filters: defineMessages({
    all: {
      id: "admin.orders.filters.all",
      defaultMessage: "All",
    },
    pending: {
      id: "admin.orders.filters.pending",
      defaultMessage: "Pending",
    },
    filled: {
      id: "admin.orders.filters.filled",
      defaultMessage: "Filled",
    },
    cancelled: {
      id: "admin.orders.filters.cancelled",
      defaultMessage: "Cancelled",
    },

    latvia: {
      id: "admin.orders.filters.latvia",
      defaultMessage: "Latvia",
    },
    estonia: {
      id: "admin.orders.filters.estonia",
      defaultMessage: "Estonia",
    },
    lithuania: {
      id: "admin.orders.filters.lithuania",
      defaultMessage: "Lithuania",
    },
  }),
};

export default merge(globalMessages, messages);
