import axiosInstance from "src/http-common";

export const fetchData = async (category: string) => {
  const response = await axiosInstance.get("/products", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      scheme: "https",
    },
    params: {
      category,
    }
  });

  return response;
};
