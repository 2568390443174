import React from "react";
import AdminScreenBase from "../components/AdminScreenBase";
import { injectIntl } from "react-intl";
import messages from "./messages";

interface HomeProps {
  intl: any;
}

const Home: React.FC<HomeProps> = ({ intl }) => {
  return (
    <AdminScreenBase title={intl.formatMessage(messages.screen.title)}>
      <div className="home">
        <div className="home-title">
          {intl.formatMessage(messages.screen.title)}
        </div>
      </div>
    </AdminScreenBase>
  );
};

export default injectIntl(Home);
