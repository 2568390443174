export const adminRedirect = (to: string, navigate: any) => {
  switch (to) {
    case "home":
      navigate("/adminka/home");
      break;
    case "adminka":
      navigate("/adminka");
      break;
    case "none":
      break;
    default:
      navigate("/adminka");
      break;
  }
};
