import React, { useState, useEffect, ComponentType } from "react";
import axios, { AxiosResponse } from "axios";
import LoadingScreen from "./LoadingScreen";
import { useNavigate, useParams } from "react-router-dom";
import { adminRedirect } from "./admin/helpers/adminRedirect";
import { useLocation } from "react-router-dom";
import ErrorScreen from "./ErrorScreen";
import translateError from "./helpers/translateError";
import { injectIntl, WrappedComponentProps } from "react-intl"; // Import WrappedComponentProps
import { AdminLoadingScreen } from "./admin/components";

interface WithDataFetchingProps {
  data: any;
}

// Use WrappedComponentProps to define intl
const withDataFetching =
  (
    fetchDataFunction: (props: any) => Promise<AxiosResponse<any>>,
    scope: string = "client"
  ) =>
  (
    WrappedComponent: ComponentType<
      WithDataFetchingProps & WrappedComponentProps
    >
  ) => {
    const WithDataFetching: React.FC = (props: any) => {
      const properties = useParams();
      const navigate = useNavigate();
      const location = useLocation();

      const [data, setData] = useState<any[] | null>(null);
      const [loading, setLoading] = useState(true);
      const [error, setError] = useState<Error | null>(null);

      const fetchData = async (summedState: any) => {
        try {
          const result = await fetchDataFunction(summedState);

          if (result?.data?.error) {
            setError(result.data.error);
            setLoading(false);
            return;
          }
          if (result?.data?.redirect) {
            adminRedirect(result.data.redirect, navigate);
          }

          setData(result.data);

          // for smooth loading screen (not sporadically flashing loading screen)
          setTimeout(() => {
            setLoading(false);
          }, 500);
        } catch (error: any) {
          setError(error);
          setLoading(false);
        }
      };

      useEffect(() => {
        const summedState = { ...location.state, ...properties };
        fetchData(summedState);
      }, [location.state, properties]); // Include all dependencies

      // i know, making a new component for this is not great, but it was the only best way to do it
      if (loading) {
        if (scope === "admin") {
          return <LoadingScreen />;
        }

        return <LoadingScreen />;
      }

      if (error) {
        const translatedError = translateError(props.intl, error);
        console.log(translatedError, "translatedError");
        console.log(error, "error");
        return <ErrorScreen error={translatedError} />;
      }

      return <WrappedComponent {...props} {...properties} data={data} />;
    };

    return injectIntl(WithDataFetching);
  };

export default withDataFetching;
