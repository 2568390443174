import { toast } from "react-toastify";

export const Notification = (message: string, type: string) => {
  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      theme: "light",
      className: `toast-${type}`,
    });
  } else {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true,
      theme: "light",
      className: `toast-${type}`,
    });
  }
};
