import axiosInstance from "src/http-common";

export const createProduct = async (product: any) => {
  const response = await axiosInstance.post(
    "/adminka/new-product",
    {
      product,
    },
    {
      withCredentials: true,
    }
  );

  return response;
};

export const validatePage = async () => {
  const response = await axiosInstance.get("/adminka/validate-admin", {
    withCredentials: true,
  });

  console.log(response, "response");
  return response;
};
