import React from "react";

interface ItemListProps {
  items: any[] | null;
  Component: any;
  options?: any | null;
}

const ItemList: React.FC<ItemListProps> = ({ items, Component, options }) => {
  return (
  <div className="item-list px-2 xs:m-2 xs:mt-3 grid sm!gap-y-6 grid-cols-1 !items-stretch	 sm:gap-6 xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 sm:mx-4 md:mx-6">
      {items &&
        items.map((item, index) => {
          return <Component options={options} item={item} key={index} />;
        })}
    </div>
  );
};

export default ItemList;
