import axiosInstance from "src/http-common";

interface ConfirmCheckoutData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: string;
  station: string | null;
}

export const getCheckoutItems = async (state: any) => {

  console.log(state, "stately")
  if(state?.mode === "buy-now") {
    const response = await axiosInstance.get("/checkout", {
      withCredentials: true,
      params: {
        buyNow: true,
        itemId: state.itemId,
        quantity: state.quantity
      }
    });

    console.log("resp", response)
    return response
  }
  const response = await axiosInstance.get("/checkout", {
    withCredentials: true,
  });

  console.log("resp", response)
  return response;
};

export const confirmCheckout = async (data: ConfirmCheckoutData, state: any) => {

  if(state?.mode === "buy-now") {
    const response = await axiosInstance.post(
      "/checkout",
      {
        data,
        buyNow: true,
        itemId: state.itemId,
        quantity: state.quantity
      },
      {
        withCredentials: true,
      }
    );

    return response;
  }
  
  const response = await axiosInstance.post(
    "/checkout",
    {
      data,
    },
    {
      withCredentials: true,
    }
  );

  return response;
};

export const getOptions = async () => {
  const response = await fetch("https://www.omniva.lv/locations.json");

  return response.json();
};
