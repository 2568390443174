import globalMessages from "src/globalMessages";
import { defineMessages, defineMessage } from "react-intl";
import { merge } from "lodash";

const messages = {
  title: defineMessage({
    id: "reviews_page.title",
    defaultMessage: "Reviews for {name}",
  }),

  text: defineMessages({
    leave_review: {
      id: "reviews_page.text.leave_review",
      defaultMessage: "Leave a review",
    },

    reviews_header: {
      id: "reviews_page.text.reviews_header",
      defaultMessage: "Reviews",
    },

    no_reviews: {
      id: "reviews_page.text.no_reviews",
      defaultMessage: "No reviews yet",
    },
  }),

  form: defineMessages({
    review: {
      id: "reviews_page.form.review",
      defaultMessage: "Your review",
    },
    name: {
      id: "reviews_page.form.name",
      defaultMessage: "Your name",
    },
    email: {
      id: "reviews_page.form.email",
      defaultMessage: "Your email",
    },
  }),
  notifications: {
    review_success: defineMessage({
      id: "reviews_page.notifications.review_success",
      defaultMessage: "Review added successfully",
    }),
  },
};

export default merge({}, globalMessages, messages);
