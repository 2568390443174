const reviews = [
  {
    name: "John Doe",
    rating: 3,
    comment: "Good product 29342034923492349-23492-0349-023940-2394-02394-20934-02934-0",
  },
  {
    name: "Jane Doe",
    rating: 5,
    comment: "Great product",
  },
];

export { reviews };
