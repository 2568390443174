import React, { useState, useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader, MarkerClusterer } from "@react-google-maps/api";

const mapContainerStyle = {
  height: "100%",
  width: "100%",
};

function App({ data, country = "Latvia", selectedPoint, onSelect }: any) {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDPKFYvQWRQwCahURAZR7_NU9HOaJZ4Ow4", // Add your API key here
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [hoveredMarker, setHoveredMarker] = useState<number | null>(null);

  const positions: any = {
    latvia: { lat: 56.946285, lng: 24.105078 },
    lithuania: { lat: 55.946285, lng: 23.105078 },
    estonia: { lat: 57.946285, lng: 25.105078 },
  };

  const chooseFocus = () => {
    if (selectedPoint) {
      return {
        center: {
          lat: parseFloat(selectedPoint?.Y_COORDINATE),
          lng: parseFloat(selectedPoint?.X_COORDINATE),
        },
        zoom: 8, // Adjusted zoom level for more zoomed-out view
      };
    }
    return {
      center: positions[country.toLowerCase()],
      zoom: 8, // Adjusted zoom level for more zoomed-out view
    };
  };

  const focusConfig = chooseFocus();

  const handleMarkerHover = (index: number) => setHoveredMarker(index);

  const handleMarkerLeave = () => setHoveredMarker(null);

  const onLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
    map.setCenter(focusConfig.center);
    map.setZoom(focusConfig.zoom);
  }, [focusConfig]);

  const onUnmount = useCallback(() => setMap(null), []);

  if (loadError) {
    return <div>Error loading map</div>;
  }

  return (
    <div className="map-container !bg-first flex-grow my-4">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={focusConfig.center}
          zoom={focusConfig.zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <MarkerClusterer>
            {(clusterer) =>
              data.map((marker: any, index: number) => {
                const isSelected = selectedPoint?.NAME === marker.NAME;
                const renderMarker = () => {
                  if (isSelected) {
                    return {
                      url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                      scaledSize: new google.maps.Size(36, 36),
                    };
                  }
                  if (index === hoveredMarker) {
                    return {
                      url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png",
                      scaledSize: new google.maps.Size(28, 28),
                    };
                  }
                  return {
                    url: "https://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                    scaledSize: new google.maps.Size(26, 26),
                  };
                };
                return (
                  <Marker
                    key={index}
                    position={{
                      lat: parseFloat(marker.Y_COORDINATE),
                      lng: parseFloat(marker.X_COORDINATE),
                    }}
                    icon={renderMarker()}
                    clusterer={clusterer}
                    onClick={() => onSelect(marker.NAME)}
                    // onMouseOver={() => handleMarkerHover(index)}
                    // onMouseOut={handleMarkerLeave}
                  />
                );
              })
            }
          </MarkerClusterer>
        </GoogleMap>
      )}
    </div>
  );
}

export default App;
