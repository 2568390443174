import React, { useRef, useState, ChangeEvent, useEffect } from "react";

interface Option {
  [key: string]: string;
}

interface SearchableDropdownProps {
  options: Option[];
  label?: string;
  id?: string;
  selectedVal: string | null;
  handleChange: (selectedValue: string | null) => void;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  options,
  label = "NAME",
  id = "1",
  selectedVal,
  handleChange,
}: SearchableDropdownProps) => {
  const [query, setQuery] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const selectOption = (option: Option) => {
    setQuery("");
    handleChange(option[label]);
    setIsOpen((prev) => !prev);
  };

  function toggle(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };

  const filter = (options: Option[]) => {
    return options.filter(
      (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            placeholder="Find your parcel station"
            className={`search-input py-3 ${isOpen ? "rounded-t-md" : "rounded-md"} !w-full !bg-white text-accent border-1 border-accent border-opacity-25 px-2`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
          />
        </div>
      </div>

      <div className={`options !bg-white border-1 border-accent border-opacity-25 ${isOpen ? "open" : ""}`}>
        {filter(options).map((option, index) => (
          <div
            onClick={() => selectOption(option)}
            className={`option hover:!bg-accent hover:!bg-opacity-15 !bg-white !border-1 !border-x-accent !border-opacity-25 ${
              option[label] === selectedVal ? "!bg-second bg-opacity-65 !text-accent font-bold" : ""
            }`}
            key={`${id}-${index}`}
          >
            {option[label]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchableDropdown;
