import axiosInstance from "src/http-common";

export const fetchOrders = async () => {
  const result = await axiosInstance.get("/adminka/orders", {
    withCredentials: true,
  });
  return result;
};

export const updateOrder = async (id: string, status: string) => {
  const result = await axiosInstance.put(
    "/adminka/orders",
    { id, status },
    { withCredentials: true }
  );
  return result;
};
