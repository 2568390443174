import React from "react";
import { useSocket } from "./SocketContext";
import { useEffect } from "react";

interface YourComponentProps {
  data: any[] | null;
  intl: any;
}

const withSubscription = (WrappedComponent: any, socketChange: string) => {
  const WithSubscription: React.FC<YourComponentProps> = (props) => {
    const socket = useSocket();
    const [passData, setPassData] = React.useState<any[] | null>(props.data);


    useEffect(() => {
      if (socket) {
        socket.on(socketChange, (data: any) => {
          if (!data?.documentKey?._id) {
            setPassData(data);
            return;
          }

          const updatedId = data.documentKey._id;
          const { updatedFields } = data.updateDescription;

          setPassData((prevData) => {
            if (prevData) {
              const toUpdate = prevData.find((item) => item._id === updatedId);
              if (toUpdate) {
                const updatedItem = { ...toUpdate, ...updatedFields };
                return prevData.map((item) =>
                  item._id === updatedId ? updatedItem : item
                );
              }
            }
            return prevData;
          });
        });
      }

      return () => {
        if (socket) {
          socket.off(socketChange);
        }
      };
    }, [socket]);

    return <WrappedComponent {...props} data={passData} />;
  };

  return WithSubscription;
};

export default withSubscription;
