import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { addToCart, getProduct, putReview } from ".";
import { reviews } from "./testdata";
import {
  withDataFetching,
  ScreenBase,
  ActionButton,
  withHandleResponse,
  MenuSeparator,
  Carousel,
  ReviewCarousel,
} from "src/components/index";
import {
  Facebook,
  Instagram,
  Twitter,
  MapPin,
  Truck,
  AlertCircle,
} from "react-feather";
import { Pinterest, Shop } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import ReactStars from "react-stars";
import { AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { Notification } from "src/components/helpers/notification";
import messages from "./messages";
import "bootstrap/dist/css/bootstrap.css";
import formatPrice from "src/components/helpers/formatPrice";
import { useOverlay } from "src/components/OverlayProvider";

interface ProductPageProps {
  data: any | null;
  intl: any;
  handleResponse: (response: any) => AxiosResponse;
}

interface FormValues {
  name: string;
  email: string;
  rating: number;
  review: string;
}

const ProductPage: React.FC<ProductPageProps> = ({
  intl,
  data,
  handleResponse,
}) => {
  const language = intl.locale;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const {
    overlayVisible,
    cartOverlayVisible,
    toggleOverlay,
    toggleCartOverlay,
  } = useOverlay();

  const navigate = useNavigate();
  const {
    _id: id,
    name,
    description,
    price,
    stock,
    images,
    discount,
    glob,
  } = data;
  const [count, setCount] = useState(stock ? 1 : 0);

  const averageRating = reviews?.reduce(
    (acc: number, review: any) => acc + review.rating / reviews.length,
    0
  );

  const onSubmit = async (data: FormValues) => {
    const response = await putReview({ ...data, id }, glob);

    const responseData = handleResponse(response);

    if (responseData) {
      reset();
    }
  };

  return (
    <ScreenBase title={name[language] ?? name["en-gb"]}>
      <div className="product-container !bg-first !text-accent m-2 rounded-md">
        <div className="product-media mx-2 md:mx-4">
          <div className="product-image !w-fit md:!w-max !h-auto self-center lg:self-start">
            <Carousel images={images} />
          </div>
          <div className="product-description !text-inherit">
            <div className="product-actions !text-inherit">
              <div className="product-stats px-4">
                <div className="product-name !text-accent font-semibold">
                  {name[language]}
                </div>
                <div
                  className={`product-rating ${
                    reviews.length == 0 ? "opacity-65" : null
                  } !mx-0 !text-lg !text-accent font-medium my-2 !px-0 cursor-pointer`}
                  onClick={() =>
                    navigate(`/product/${glob}/reviews`, {
                      state: { id, glob },
                    })
                  }
                >
                  <ReactStars
                    className="product-rating-stars !cursor-pointer !px-0 mr-1"
                    count={5}
                    edit={false}
                    value={averageRating}
                    size={20}
                    color2={"#e89b27"}
                  />
                  ({averageRating})
                  <div className="text-second mx-2 hover:underline">
                    {`${reviews.length}`} Reviews
                  </div>
                </div>

                {discount > 0 ? (
                  // strikethrough original price
                  <div className="product-price !text-xl my-2 !text-accent">
                    <span className="discounted-price !text-accent">
                      {intl.formatMessage(messages.text.price_format, {
                        price: (price * (1 - discount / 100)).toFixed(2),
                      })}
                    </span>
                    <span className="original-price !text-accent">
                      {intl.formatMessage(messages.text.price_format, {
                        price: price.toFixed(2),
                      })}
                    </span>

                    <span className="discount !text-accent">
                      {intl.formatMessage(messages.text.discount, {
                        discount: discount,
                      })}
                    </span>
                  </div>
                ) : (
                  <div className="product-price !text-2xl my-2 mb-1 !text-accent">
                    {formatPrice(price)}
                  </div>
                )}

                <div className="text-accent mb-3 !text-base opacity-75">
                  Tax included. Shipping calculated at checkout.
                </div>

                <div className="product-stock font-semibold !text-lg opacity-75 !text-accent">
                  {stock < 10 ? (
                    <div className="text-red flex flex-row items-center">
                      <AlertCircle size={16} />
                      <span className="mx-2">
                        <span className="mr-1">{stock}</span>
                        {intl.formatMessage(messages.text.product_stock)}
                      </span>
                    </div>
                  ) : (
                    <div className="text-green">
                      <span>
                        <span className="mr-1">{stock}</span>
                        {intl.formatMessage(messages.text.product_stock)}
                      </span>
                    </div>
                  )}
                </div>

                <div className="product-banner-actions">
                  <div className="item-banner-actions !justify-between !w-fit lg:!w-full">
                    <div className="flex flex-row mr-5">
                      <div
                        className="item-banner-crease !bg-first !border-1 !border-accent !border-opacity-30 rounded-l-full"
                        onClick={() => {
                          if (count > 1) {
                            setCount((prev) => prev - 1);
                          }
                        }}
                      >
                        -
                      </div>
                      <input
                        type="text"
                        value={count}
                        className="count-input !border-y !border-accent !border-opacity-30	 !bg-first !text-accent"
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          if (Number(inputValue) <= stock) {
                            setCount(Number(inputValue));
                          }
                        }}
                      />
                      <div
                        className="item-banner-crease !bg-first !border-1 !border-accent !border-opacity-30	 rounded-r-full"
                        onClick={() =>
                          setCount((prev) => {
                            if (prev < stock) {
                              return prev + 1;
                            }
                            return prev;
                          })
                        }
                      >
                        +
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col nd:flex-row my-3 ">
                  <div
                    className="flex flex-grow  !p-0.5 my-2 nd:my-0 nd:mr-2 items-center justify-center !text-accent cursor-pointer !text-base !text-accent !font-semibold !border !border-accent !rounded-md py-3 px-4 nd:!py-2"
                    onClick={async () => {
                      const response = await addToCart(id, count);

                      const respData = handleResponse(response);

                      if (respData) {
                        Notification(
                          intl.formatMessage(
                            messages.notifications.review_success
                          ),
                          "success"
                        );
                      }

                      toggleCartOverlay();
                      setCount(1);
                    }}
                  >
                    {intl.formatMessage(messages.buttons.add_to_cart)}
                  </div>

                  <div
                    onClick={() => {
                      navigate("/confirm-checkout", {
                        state: {
                          mode: "buy-now",
                          itemId: id,
                          quantity: count,
                        },
                      });
                    }}
                    className="!p-0.5 flex flex-grow my-2 nd:my-0 !text-accent nd:ml-2 cursor-pointer !text-base !text-first !bg-second !font-semibold !border !border-second !rounded-md py-3 px-4 nd:!py-2 items-center justify-center"
                  >
                    Buy now
                  </div>
                </div>
                <MenuSeparator />
                <h2 className="subsection-title text-thin opacity-65 !text-base !uppercase !text-accent">
                  {intl.formatMessage(messages.text.product_description)}
                </h2>
                <div className="product-banner-description !text-lg  !text-accent">
                  {description[language] ?? description["en-gb"]}
                </div>
                <MenuSeparator />
                <div className="">
                  <h2 className="subsection-title text-thin opacity-65 !text-base !uppercase !text-accent">
                    Details
                  </h2>

                  <div className="flex flex-row !text-sm !text-accent items-center mt-4">
                    <div className="opacity-75">
                      <MapPin size={16} />
                    </div>
                    <span className="ml-2 opacity-75">Sent from</span>
                    <span className="ml-1 font-semibold">Riga, Latvia</span>
                  </div>
                  <div className="flex flex-row !text-sm !text-accent items-center my-4">
                    <div className="opacity-75">
                      <Truck size={16} />
                    </div>
                    <span className="ml-2 opacity-75">Estimated shipping</span>
                    <span className="ml-1 font-semibold">
                      3-5 business days
                    </span>
                  </div>
                </div>
                <MenuSeparator />
                <div className="">
                  <h2 className="subsection-title text-thin opacity-65 !text-base !uppercase !text-accent">
                    {intl.formatMessage(messages.text.share_product)}
                  </h2>
                  <div className="social-media-icons mt-3 flex items-center justify-center">
                    <i className="fab fa-facebook-f !text-accent">
                      <Facebook />
                    </i>
                    <i className="fab fa-twitter !text-accent">
                      <Twitter />
                    </i>
                    <i className="fab fa-instagram !text-accent">
                      <Instagram />
                    </i>
                    <i className="fab fa-pinterest !text-accent">
                      <Pinterest />
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-grow mx-2 px-4">
          <MenuSeparator />
        </div>

        <div className="product-text !text-accent flex flex-grow flex-col md:!flex-row my-2 px-4 mx-2 md:mx-4">
          <div className="flex flex-grow flex-col w-full mr-4 my-6 sm:my-0 pr-6">
            <h2 className="subsection-title text-thin opacity-65 !text-base !uppercase !text-accent">
              Customer reviews
            </h2>

            <div className="">
              <ReviewCarousel reviews={reviews} />
            </div>

            <div
              onClick={() => navigate(`reviews`)}
              className="text-second text-base flex flex-grow w-full justify-end hover:underline font-medium cursor-pointer"
            >
              View all
            </div>
          </div>
          <form
            className="product-review-form flex flex-grow nd:w-full lg:pl-4"
            onSubmit={handleSubmit((data) => {
              onSubmit(data);
            })}
          >
            <h2 className="subsection-title text-thin opacity-65 !text-base !uppercase !text-accent">
              {intl.formatMessage(messages.text.leave_review)}
            </h2>

            {/* Name Field */}
            <label className="input-label !text-base !text-accent">
              {intl.formatMessage(messages.text.name)}
            </label>
            <input
              minLength={1}
              maxLength={20}
              required={true}
              className={`form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent ${
                errors.name ? "!border-error" : ""
              }`}
              type="text"
              {...register("name", {
                required: "Name is required",
                minLength: 1,
                maxLength: 20,
              })}
              placeholder={intl.formatMessage(messages.form.name)}
            />
            {errors.name && (
              <p className="text-error text-sm">{errors.name.message}</p>
            )}

            {/* Email Field */}
            <label className="input-label !text-base !text-accent">
              {intl.formatMessage(messages.text.email)}
            </label>
            <input
              required={true}
              className={`form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent ${
                errors.email ? "!border-error" : ""
              }`}
              type="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Enter a valid email",
                },
              })}
              placeholder={intl.formatMessage(messages.form.email)}
            />
            {errors.email && (
              <p className="text-error text-sm">{errors.email.message}</p>
            )}

            {/* Rating Field */}
            <label className="input-label !text-base !text-accent">
              {intl.formatMessage(messages.text.rating)}
            </label>
            <ReactStars
              count={5}
              onChange={(rating) => setValue("rating", rating)}
              size={20}
              color2={"#e89b27"}
            />
            {errors.rating && (
              <p className="text-error text-sm">{errors.rating.message}</p>
            )}

            {/* Review Field */}
            <label className="input-label !text-base !text-accent">
              {intl.formatMessage(messages.text.review)}
            </label>
            <textarea
              maxLength={200}
              required={true}
              className={`review-textarea !bg-white !border !border-accent !border-opacity-25 !py-2 !text-accent ${
                errors.review ? "!border-error" : ""
              }`}
              {...register("review", {
                required: "Review is required",
                maxLength: {
                  value: 200,
                  message: "Review cannot exceed 200 characters",
                },
              })}
              placeholder={intl.formatMessage(messages.form.review)}
            ></textarea>
            {errors.review && (
              <p className="text-error text-sm">{errors.review.message}</p>
            )}

            {/* Submit Button */}
            <ActionButton
              title="Confirm"
              onClick={handleSubmit((data) => {
                onSubmit(data);
              })}
            />
          </form>
        </div>
      </div>
    </ScreenBase>
  );
};

const ComponentWithHandleResponse = withHandleResponse(ProductPage);
const ComponentWithData = withDataFetching(
  async (state) => await getProduct(state.glob)
)(injectIntl(ComponentWithHandleResponse));

export default ComponentWithData;
