import React, { useState } from "react";
import { injectIntl } from "react-intl";
import messages from "./messages";
import {
  withDataFetching,
  ScreenBase,
  NoItemScreen,
  withHandleResponse,
} from "src/components/index";
import { useForm } from "react-hook-form";
import { getReviews, addReview } from ".";
import ReactStars from "react-stars";
import { ActionButton } from "src/components/index";
import { AxiosResponse } from "axios";
import { Notification } from "src/components/helpers/notification";
import { lala } from "./reviews";
import { useNavigate } from "react-router-dom";

interface YourComponentProps {
  data: {
    id: string;
    glob: string;
    name: string;
    reviews: any[];
  };
  intl: any;
  handleResponse: (response: any) => AxiosResponse;
}

interface FormValues {
  id: string;
  name: string;
  email: string;
  rating: number;
  review: string;
}

const YourComponent: React.FC<YourComponentProps> = ({
  data,
  intl,
  handleResponse,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();

  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const itemsPerPage = 5;

  data.reviews = lala;
  const onSubmit = async (formData: FormValues) => {
    formData.id = data.id;
    const response = await addReview(formData, data.glob);

    const responseData = handleResponse(response);

    if (responseData) {
      Notification(
        intl.formatMessage(messages.notifications.review_success),
        "success"
      );
      reset();
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(data.reviews.length / itemsPerPage);
  const paginatedReviews = data.reviews.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <ScreenBase
      title={intl.formatMessage(messages.title, {
        name: data.name[intl.locale],
      })}
    >
      <div className="flex flex-col my-4 mx-3">
        <div
          onClick={() => {
            navigate(`/product/${data.glob}`);
          }}
          className="text-second text-base my-3 px-4 hover:underline font-medium cursor-pointer"
        >
          Back to product
        </div>
        <div className="product-container flex flex-grow w-full flex-col md:flex-row !bg-first">
          <div className="flex flex-col w-full md:w-1/2 items-center flex-grow px-3">
            <div className="product-reviews-container flex w-full items-center md:!mx-6 h-full">
              <h2 className="subsection-title text-thin opacity-65 !text-base !uppercase !text-accent self-start">
                Review list
              </h2>

              {data && data?.reviews && data?.reviews?.length > 0 ? (
                paginatedReviews.map((item: any, index: number) => (
                  <div
                    className="product-review py-2 px-3 w-full	 md:w-full !border !border-accent !border-opacity-15 my-3"
                    key={index}
                  >
                    <div className="review-header font-bold flex items-center p-0">
                      <h3 className="text-accent !text-lg mr-4 mb-0">
                        {item.name}
                      </h3>
                      <div className="review-rating">
                        <ReactStars
                          count={5}
                          value={item.rating}
                          size={16}
                          color2={"#e89b27"}
                          edit={false}
                        />
                      </div>
                    </div>
                    <p className="text-accent text-lg my-2 text-wrap">
                      {item.comment}
                    </p>
                  </div>
                ))
              ) : (
                <NoItemScreen
                  title={intl.formatMessage(messages.text.no_reviews)}
                />
              )}
            </div>
            <div className="pagination flex w-full justify-center mt-2 mb-4 w-full md:mx-6 w-4/5 md:w-full">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  className={`px-2 mx-1 text-lg rounded-md ${
                    currentPage === index + 1
                      ? "bg-second text-first"
                      : "bg-second bg-opacity-10 text-accent"
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-col w-full md:w-1/2 items-center flex-grow px-4 justify-center">
            <form
              className="product-reviews-container flex w-full md:!mx-6 h-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 className="subsection-title text-thin opacity-65 !text-base !uppercase !text-accent">
                {intl.formatMessage(messages.text.leave_review)}
              </h2>

              {/* Name Field */}
              <label className="input-label !text-base !text-accent">
                {intl.formatMessage(messages.text.name)}
              </label>
              <input
                className={`form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent ${
                  errors.name ? "!border-red-500" : ""
                }`}
                type="text"
                {...register("name", {
                  required: "Name is required",
                  minLength: 1,
                  maxLength: 20,
                })}
                placeholder={intl.formatMessage(messages.form.name)}
              />
              {errors.name && (
                <p className="text-error text-sm">{errors.name.message}</p>
              )}

              {/* Email Field */}
              <label className="input-label !text-base !text-accent">
                {intl.formatMessage(messages.text.email)}
              </label>
              <input
                className={`form-input !w-full !bg-white !border !border-accent !border-opacity-25 px-2 py-3 !text-accent ${
                  errors.email ? "!border-error" : ""
                }`}
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Enter a valid email",
                  },
                })}
                placeholder={intl.formatMessage(messages.form.email)}
              />
              {errors.email && (
                <p className="text-error text-sm">{errors.email.message}</p>
              )}

              {/* Rating Field */}
              <label className="input-label !text-base !text-accent">
                {intl.formatMessage(messages.text.rating)}
              </label>
              <ReactStars
                count={5}
                onChange={(rating) => setValue("rating", rating)}
                size={20}
                color2={"#e89b27"}
              />
              {errors.rating && (
                <p className="text-error text-sm">{errors.rating.message}</p>
              )}

              {/* Review Field */}
              <label className="input-label !text-base !text-accent">
                {intl.formatMessage(messages.text.review)}
              </label>
              <textarea
                className={`review-textarea !bg-white !border !border-accent !border-opacity-25 !py-2 !text-accent ${
                  errors.review ? "!border-error" : ""
                }`}
                {...register("review", {
                  required: "Review is required",
                  maxLength: {
                    value: 200,
                    message: "Review cannot exceed 200 characters",
                  },
                })}
                placeholder={intl.formatMessage(messages.form.review)}
              ></textarea>
              {errors.review && (
                <p className="text-error text-sm">{errors.review.message}</p>
              )}

              <div className="my-2"></div>

              {/* Action Button */}
              <ActionButton
                title="Confirm"
                onClick={handleSubmit((data) => {
                  onSubmit(data);
                })}
              />

              <div className="my-2"></div>
            </form>
          </div>
        </div>
      </div>
    </ScreenBase>
  );
};

const ComponentWithHandleResponse = withHandleResponse(YourComponent);
const ComposedComponent = withDataFetching(
  async (state): Promise<AxiosResponse<any, any>> => {
    console.log("statessss", state);
    return await getReviews(state.glob);
  }
)(injectIntl(ComponentWithHandleResponse));
export default ComposedComponent;
