import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  HomePage,
  CartPage,
  ShopPage,
  ProductPage,
  ConfirmCheckoutPage,
  Payment,
  Completion,
  NotFound,
  ReviewsPage,
} from "src/pages/index";
import {
  ProductPageAdmin,
  AdminHome,
  AdminLogin,
  EditProductAdmin,
  CreateProductAdmin,
  OrdersAdmin,
} from "src/components/admin/index";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <BrowserRouter>
      <>
        <div>
          <ToastContainer />
        </div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/product/:glob" element={<ProductPage />} />
          <Route path="/product/:glob/reviews" element={<ReviewsPage />} />
          <Route path="/confirm-checkout" element={<ConfirmCheckoutPage />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/completion" element={<Completion />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/shop/:category" element={<ShopPage key={window.location.pathname} />} />

          {/*  admin  */}
          <Route path="/adminka" element={<AdminLogin />} />
          <Route path="/adminka/home" element={<AdminHome />} />
          <Route path="/adminka/products" element={<ProductPageAdmin />} />
          <Route path="/adminka/product/:id" element={<EditProductAdmin />} />
          <Route path="/adminka/create" element={<CreateProductAdmin />} />
          <Route path="/adminka/orders" element={<OrdersAdmin />} />
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;
