import { injectIntl } from "react-intl";
import messages from "./messages";
import { Link, ScreenBase } from "src/components/index";
import { ShoppingBag } from "react-feather";
import logo from "src/public/switches.png";
import { useEffect, useState } from "react";
import document from "src/public/kaka.webp";

function HomePage({ intl }: any) {
  const [showZaza, setShowZaza] = useState(false);

  useEffect(() => {
    // Trigger the animation for "zaza" after "Welcome" animation completes
    const timer = setTimeout(() => {
      setShowZaza(true);
    }, 2000); // Adjust timing as needed
    return () => clearTimeout(timer);
  }, []);

  return (
    <ScreenBase title={intl.formatMessage(messages.home)}>
      <div className="error-container relative flex flex-col flex-grow items-center justify-center">
        {/* 404 Text */}
        <h1 className="welcome-text !text-accent absolute inset-0 flex justify-center items-center xs:text-8xl nd:text-9xl sm:text-10xl md:text-12xl xl:text-16xl tracking-wider opacity-10 text-accent font-semibold">
          Welcome
        </h1>

        <div
          className={`zaza-text mb-8  w-full !flex !flex-grow items-center flex-col ${
            showZaza ? "slide-in" : ""
          }`}
        >
          <h2 className="text-2xl self-center text-center !font-light my-6 xs:w-full sm:w-[60%] md:text-4xl text-accent font-semibold mb-8">
            Your one stop shop for all your keyboard needs
          </h2>

          <div className="flex flex-grow w-full px-6 grid grid-cols-1 md:grid-cols-2 gap-4 nd:w-[85%]  sm:w-[75%]">
            {/* All */}
            <div className="relative bg-black rounded-lg flex items-center justify-center transition duration-500 ease-in-out hover:bg-opacity-80">
              <div className="relative flex flex-col items-center">
                <span className="text-white text-4xl tracking-wide font-bold">
                  All
                </span>
                <a
                  href="/shop/all"
                  className="text-first mt-2 text-lg cursor-pointer no-underline flex items-center"
                >
                  Discover
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-4 ml-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>

            {/* Keycaps */}
            <div className="relative bg-black rounded-lg flex items-center justify-center transition-background-color duration-500 ease-in-out hover:bg-white">
              <div className="relative flex flex-col items-center">
                <span className="text-white text-4xl tracking-wide font-bold">
                  Keycaps
                </span>
                <a
                  href="/shop/keycaps"
                  className="text-first mt-2 text-lg cursor-pointer no-underline flex items-center"
                >
                  Discover
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-4 ml-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>

            {/* Switches */}
            <div className="relative bg-black rounded-lg flex items-center justify-center transition duration-500 ease-in-out hover:bg-opacity-80">
              <div className="relative flex flex-col items-center">
                <span className="text-white text-4xl tracking-wide font-bold">
                  Switches
                </span>
                <a
                  href="/shop/switches"
                  className="text-first mt-2 text-lg cursor-pointer no-underline flex items-center"
                >
                  Discover
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-4 ml-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>

            {/* Accessories */}
            <div className="relative bg-black rounded-lg flex items-center justify-center transition duration-500 ease-in-out hover:bg-opacity-80">
              <div className="relative flex flex-col items-center">
                <span className="text-white text-4xl tracking-wide font-bold">
                  Accessories
                </span>
                <a
                  href="/shop/accessories"
                  className="text-first mt-2 text-lg cursor-pointer  no-underline  flex items-center"
                >
                  Discover
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-4 ml-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>

            {/* Tools */}
          </div>
        </div>
      </div>
    </ScreenBase>
  );
}

export default injectIntl(HomePage);
