import React from "react";

interface NoItemScreenProps {
  title: string;
}

const NoItemScreen: React.FC<NoItemScreenProps> = ({ title }) => {
  return (
    <div className="center-container">
      <div className="title-container flex !text-accent">{title}</div>
      <a href="/shop/all" className="flex no-underline !text-first select-none cursor-pointer border-1 bg-second border-second rounded-full p-3 m-3 font-bold">
        Continue shopping
      </a>
    </div>
  );
};

export default NoItemScreen;
