import React, { useEffect } from "react";
import "src/components/index.css";
import AsLink from "src/components/Link";
import messages from "src/globalMessages";
import { defineMessages, injectIntl } from "react-intl";
import withSubscription from "src/components/withSubscription";
import { useAppContext } from "src/components/CartCountContext";
import { useSocket } from "src/components/SocketContext";
import fetchCartCount from "src/components/helpers/fetchCartCount";
import { categories } from "src/const";
import LanguageDropdown from "src/components/LanguageDropdown";
import { Menu, ShoppingCart } from "react-feather";
import { Link } from "react-router-dom";
import { useOverlay } from "src/components/OverlayProvider";

interface NavbarProps {
  intl?: any;
  data?: any[] | null;
}

const localMessages = {
  categories: defineMessages({
    switches: {
      id: "categories.switches",
      defaultMessage: "Switches",
    },

    keycaps: {
      id: "categories.keycaps",
      defaultMessage: "Keycaps",
    },

    tools: {
      id: "categories.tools",
      defaultMessage: "Tools",
    },
  }),
};

const NavBar: React.FC<NavbarProps> = ({ data, intl }) => {
  const { state, dispatch } = useAppContext();

  useEffect(() => {
    console.log("data change navbar", data);
  }, [data]);
  // const [extended, setExtended] = React.useState(false);
  const socket = useSocket();
  const {
    overlayVisible,
    cartOverlayVisible,
    toggleOverlay,
    toggleCartOverlay,
  } = useOverlay();

  // remove "none" from categories
  const filteredCats = categories.filter(
    (category) => category.name !== "none"
  );

  useEffect(() => {
    try {
      fetchCartCount().then((resp: any) => {
        dispatch({
          type: "SET_CART_COUNT",
          payload: {
            count: resp.data.items.count,
            items: resp.data.items.items,
            finalValue: resp.data.items.finalValue,
            total: resp.data.items.total,
          },
        });
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("cartItemCount", (data: any) => {
        console.log("DATA UPDATE NAVBAR", data);
        dispatch({
          type: "SET_CART_COUNT",
          payload: {
            count: data.count,
            items: data.items,
            finalValue: data.finalValue,
            total: data.total,
          },
        });
      });
    }

    return () => {
      if (socket) {
        socket.off("cartItemCount");
      }
    };
  }, [socket]);

  return (
    <div className="navbar-parent flex flex-grow !bg-first" id="navbar-parent">
      <div
        id="navbar-container"
        className={
          "navbar-container w-full  grid grid-cols-3 md:flex md:!flex-grow md:!flex-row justify-between"
        }
      >
        <div className="chat md:hidden self-center mx-3  ">
          <Menu
            className="!cursor-pointer"
            onClick={() => {
              toggleOverlay();
            }}
          />
        </div>
        <a className="navbar-logo" href="/">
          KeebStore
        </a>
        <div className="navbar-links flex flex-row items-center !hidden md:!flex">
          <AsLink
            navTo="/"
            title={intl.formatMessage(messages.buttons.home)}
            className="navbar-link"
          />

          <AsLink
            navTo="/adminka/orders"
            title="orders"
            className="navbar-link"
          />

          <AsLink
            navTo="/adminka/create"
            title="Create Product"
            className="navbar-link"
          />

          <AsLink
            navTo="/adminka/products"
            title="Products"
            className="navbar-link"
          />
        </div>

        <div className="flex flex-row items-center justify-end">
          <div className="cart-icon-container mx-4">
            <div
              onClick={() => {
                toggleCartOverlay();
              }}
              className="cart-icon cursor-pointer"
            >
              <ShoppingCart size={24} />
              {<span className="cart-count mx-2 text-sm border text-first bg-second rounded-full border-accent w-6 h-6">{state.cartCount}</span>}
            </div>
          </div>
          <div className="!hidden md:!flex">
            <LanguageDropdown />
          </div>
        </div>
      </div>
      {/* <div id="navbar-toggle-bar" className={"navbar-toggle-bar"}>
        <div
          className="navbar-toggle"
          onClick={() => {
            const parentNav = document.getElementById("navbar-parent");

            const navbarContainer = document.getElementById("navbar-container");
            const navbarToggleBar =
              document.getElementById("navbar-toggle-bar");

            if (navbarToggleBar) {
              navbarToggleBar.classList.toggle("navbar-toggle-bar__extended");
            }

            if (navbarContainer) {
              navbarContainer.classList.toggle("navbar-container__extended");
            }
            if (parentNav) {
              parentNav.classList.toggle("navbar-parent__extended");
            }
          }}
        >
          <Menu />
        </div>
      </div> */}
    </div>
  );
};
export default injectIntl(withSubscription(NavBar, "cartItemCount"));
