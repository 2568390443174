import globalMessages from "src/globalMessages";
import { defineMessages } from "react-intl";
import merge from "lodash/merge";

const messages = {
  screen: defineMessages({
    title: {
      id: "admin.home.screen.title",
      defaultMessage: "Home",
    },
  }),
};

export default merge(globalMessages, messages);
