import React, { createContext, useState, useContext, ReactNode } from 'react';

interface OverlayProviderProps {
    overlayVisible: boolean;
    cartOverlayVisible: boolean;
    toggleOverlay: () => void;
    toggleCartOverlay: () => void;
    }

// Define the shape of the context value
const OverlayContext = createContext({
  overlayVisible: false,
  cartOverlayVisible: false,
  toggleOverlay: () => {},
  toggleCartOverlay: () => {},
});

export const OverlayProvider = ({ children }: { children: ReactNode }) => {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [cartOverlayVisible, setCartOverlayVisible] = useState(false);

  const toggleOverlay = () => {
    setOverlayVisible(!overlayVisible);
  };

  const toggleCartOverlay = () => {
    setCartOverlayVisible(!cartOverlayVisible);
  };

  return (
    <OverlayContext.Provider value={{ overlayVisible, cartOverlayVisible, toggleCartOverlay, toggleOverlay }}>
      {children}
    </OverlayContext.Provider>
  );
};

export const useOverlay = () => {
  return useContext(OverlayContext);
};
