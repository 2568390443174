import React from "react";

interface ActionButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  title?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  title = "",
  icon,
  disabled,
  className,
}) => {
  return (
    <button className={`action-button rounded-md !bg-second !p-2 px-3`} onClick={onClick} disabled={disabled}>
      {title.length > 0 && <div className={`action-button_title text-lg !text-first font-semibold ${className}`}>{title}</div>}
      {icon && <div className={`action-button_icon ${className}`}>{icon}</div>}
    </button>
  );
};

export default ActionButton;
