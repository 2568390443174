import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  Dispatch,
} from "react";



interface AppState {
  cartCount: number;
  items:  any[];
  total: {
    totalAfterDiscount: number;
    totalBeforeDiscount: number;
  };
}

interface AppContextProps {
  state: AppState;
  dispatch: Dispatch<any>; // You can replace 'any' with your specific action type
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

function appReducer(state: AppState, action: any) {
  switch (action.type) {
    case "SET_CART_COUNT":
      return { ...state, cartCount: action.payload.count, items: action.payload.items, total: {
        totalAfterDiscount: action.payload.finalValue,
        totalBeforeDiscount: action.payload.total,
      }  };
    default:
      return state;
  }
}

const initialState: AppState = {
  cartCount: 0,
  items: [],
  total: {
    totalAfterDiscount: 0,
    totalBeforeDiscount: 0,
  },
};

function CartProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
}

function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
}

export { CartProvider, useAppContext };
