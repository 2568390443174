import { defineMessage, defineMessages } from "react-intl";
import globalMessages from "src/globalMessages";
import { merge } from "lodash";

const messages = {
  confirm_checkout: defineMessages({
    title: {
      id: "confirm_checkout.title",
      defaultMessage: "Confirm Checkout",
    },
  }),

  labels: defineMessages({
    first_name: {
      id: "confirm_checkout.labels.first_name",
      defaultMessage: "First Name",
    },
    last_name: {
      id: "confirm_checkout.labels.last_name",
      defaultMessage: "Last Name",
    },
    email: {
      id: "confirm_checkout.labels.email",
      defaultMessage: "Email",
    },
    phone: {
      id: "confirm_checkout.labels.phone",
      defaultMessage: "Phone",
    },
    address: {
      id: "confirm_checkout.labels.address",
      defaultMessage: "Address",
    },
    city: {
      id: "confirm_checkout.labels.city",
      defaultMessage: "City",
    },
    zip: {
      id: "confirm_checkout.labels.zip",
      defaultMessage: "Zip",
    },
    country: {
      id: "confirm_checkout.labels.country",
      defaultMessage: "Country",
    },
  }),

  inputs: defineMessages({
    first_name: {
      id: "confirm_checkout.inputs.first_name",
      defaultMessage: "Enter your first name",
    },
    last_name: {
      id: "confirm_checkout.inputs.last_name",
      defaultMessage: "Enter your last name",
    },
    email: {
      id: "confirm_checkout.inputs.email",
      defaultMessage: "Enter your email",
    },
    phone: {
      id: "confirm_checkout.inputs.phone",
      defaultMessage: "Enter your phone",
    },
    address: {
      id: "confirm_checkout.inputs.address",
      defaultMessage: "Enter your address",
    },
    city: {
      id: "confirm_checkout.inputs.city",
      defaultMessage: "Enter your city",
    },
    zip: {
      id: "confirm_checkout.inputs.zip",
      defaultMessage: "Enter your zip",
    },
    country: {
      id: "confirm_checkout.inputs.country",
      defaultMessage: "Enter your country",
    },
  }),

  error: defineMessages({
    first_name_required: {
      id: "confirm_checkout.error.first_name_required",
      defaultMessage: "First name is required",
    },
    first_name_length: {
      id: "confirm_checkout.error.first_name_length",
      defaultMessage: "First name must be less than 20 characters",
    },
    last_name_required: {
      id: "confirm_checkout.error.last_name_required",
      defaultMessage: "Last name is required",
    },
    last_name_length: {
      id: "confirm_checkout.error.last_name_length",
      defaultMessage: "Last name must be less than 20 characters",
    },
    email_required: {
      id: "confirm_checkout.error.email_required",
      defaultMessage: "Email is required",
    },
    email_invalid: {
      id: "confirm_checkout.error.email_invalid",
      defaultMessage: "Email is invalid",
    },
    phone_required: {
      id: "confirm_checkout.error.phone_required",
      defaultMessage: "Phone is required",
    },
    phone_invalid: {
      id: "confirm_checkout.error.phone_invalid",
      defaultMessage: "Phone is invalid",
    },
    country_required: {
      id: "confirm_checkout.error.country_required",
      defaultMessage: "Country is required",
    },
    country_invalid: {
      id: "confirm_checkout.error.country_invalid",
      defaultMessage: "Country is invalid",
    },
  }),

  text: defineMessages({
    total_price: {
      id: "confirm_checkout.text.total_price",
      defaultMessage: "Total price: {price} €",
    },
    shipping_information: {
      id: "confirm_checkout.text.shipping_information",
      defaultMessage: "Shipping information",
    },
    personal_information: {
      id: "confirm_checkout.text.personal_information",
      defaultMessage: "Personal information",
    },
    parcel_container_location: {
      id: "confirm_checkout.text.parcel_container_location",
      defaultMessage: "Parcel container location",
    },
    country: {
      id: "confirm_checkout.text.country",
      defaultMessage: "Select country",
    },
  }),

  countries: defineMessages({
    lithuania: {
      id: "confirm_checkout.countries.lithuania",
      defaultMessage: "🇱🇹 Lithuania",
    },
    latvia: {
      id: "confirm_checkout.countries.latvia",
      defaultMessage: "🇱🇻 Latvia",
    },
    estonia: {
      id: "confirm_checkout.countries.estonia",
      defaultMessage: "🇪🇪 Estonia",
    },
  }),
};

export default merge({}, globalMessages, messages);
