import React, { useState, useEffect } from "react";
import messages from "src/globalMessages";
import ActionButton from "./ActionButton";
import { ShoppingCart } from "react-feather";
import { useAppContext } from "./LanguageContext";
import { useNavigate } from "react-router-dom";
import { navigateToProduct } from "./helpers";
import ReactStars from "react-stars";
import { injectIntl } from "react-intl";
import { AxiosResponse } from "axios";
import { Notification } from "./helpers/notification";
import withHandleResponse from "./withHandleResponse";
import axiosInstance from "src/http-common";
import { useOverlay } from "./OverlayProvider";

interface ItemBannerProps {
  intl: any;
  handleResponse: (response: any) => AxiosResponse;
  item: {
    name: {
      [key: string]: string;
    };
    description: {
      [key: string]: string;
    };
    images: string[];
    price: number;
    _id: string;
    stock: number;
    reviews: any[];
    discount: number;
    glob: string;
  };
}

const Price: React.FC<{
  price: number;
  discount: number;
  intl: any;
}> = ({ price, discount, intl }) => {
  const discountedPrice = (price - (price * discount) / 100).toFixed(2);
  const originalPrice = price.toFixed(2);

  return (
    <div className="product-price-banner !text-accent opacity-75 !text-base !my-2 sm:!text-base">
      {discount > 0 ? (
        <>
          <span className="discounted-price !text-accent !text-base sm:!text-base">
            {intl.formatMessage(messages.text.price_format, {
              price: discountedPrice,
            })}
          </span>
          <span className="  line-through !text-accent !text-base sm:!text-base">
            {intl.formatMessage(messages.text.price_format, {
              price: originalPrice,
            })}
          </span>
          <span className="discount !text-base hidden ml-1 sm:flex !text-accent sm:!text-base">
            {intl.formatMessage(messages.text.discount, {
              discount: discount,
            })}
          </span>
        </>
      ) : (
        <span>
          {intl.formatMessage(messages.text.price_format, {
            price: originalPrice,
          })}
        </span>
      )}
    </div>
  );
};

const Rating: React.FC<{ reviews: any[]; intl: any }> = ({ reviews, intl }) => {
  const averageRating =
    reviews?.reduce((acc: number, review: any) => acc + review.rating, 0) /
      reviews.length || 0;

  return (
    <div className="product-rating-container !text-accent !text-base !select-none">
      <div className="product-rating-xo1 text-base">
        {intl.formatMessage(messages.text.reviews, {
          reviews: reviews.length,
        })}
      </div>
      <span>{averageRating.toFixed(1)}</span>
      <span className="ml-1 text-star">★</span>
    </div>
  );
};

const QuantitySelector: React.FC<{
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  stock: number;
}> = ({ count, setCount, stock }) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent propagation to parent div
  };

  return (
    <div className="flex flex-row mr-3" onClick={handleClick}>
      <button
        className="item-banner-crease !hidden nd:!flex !bg-first !border-1 !border-accent !border-opacity-30 rounded-l-full"
        onClick={() => setCount((prev) => Math.max(1, prev - 1))}
        aria-label="Decrease quantity"
      >
        -
      </button>
      <input
        type="text"
        value={count}
        className="sound-input !px-1 !border rounded-lg nd:rounded-none nd:!border-x-0 nd:!border-y !w-14 sm:w-16 !border-accent !border-opacity-30 !bg-first !text-accent"
        onChange={(event) => {
          const value = Number(event.target.value);
          if (!isNaN(value) && value <= stock && value >= 1) {
            setCount(value);
          }
        }}
        aria-label="Quantity input"
      />
      <button
        className="item-banner-crease !hidden nd:!flex !bg-first !border-1 !border-accent !border-opacity-30 rounded-r-full"
        onClick={() => setCount((prev) => Math.min(stock, prev + 1))}
        aria-label="Increase quantity"
      >
        +
      </button>
    </div>
  );
};

const ItemBanner: React.FC<ItemBannerProps> = ({
  item,
  intl,
  handleResponse,
}) => {
  const { name, images, price, _id, stock, reviews, discount, glob } = item;

  const [activeImage, setActiveImage] = useState(0);
  const [nextImage, setNextImage] = useState(1);
  const [isSafeToAdd, setIsSafeToAdd] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [count, setCount] = useState(stock ? 1 : 0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { toggleCartOverlay } = useOverlay();

  const { state } = useAppContext();
  const { language } = state;
  const navigate = useNavigate();

  useEffect(() => {
    if (isHovered) {
      setIsTransitioning(true);
      // const interval = setInterval(() => {
      //   setNextImage((prev) => (prev + 1) % images.length);
      //   setTimeout(() => {
      //     setActiveImage((prev) => (prev + 1) % images.length);
      //     setIsTransitioning(false);
      //   }, 500); // Sync with CSS transition duration
      // }, 3000); // Change image every 3 seconds

      return () => {
        // clearInterval(interval);
        setIsTransitioning(false);
      };
    }
  }, [isHovered, images.length]);

  const addToCart = async () => {
    if (!isSafeToAdd || count > stock || count < 1) return;

    setIsSafeToAdd(false);

    try {
      const response = await axiosInstance.post(
        "/cart",
        {
          product: { id: _id, quantity: count },
        },
        { withCredentials: true }
      );

      const responseData = handleResponse(response);

      if (responseData) {
        setCount(1);
      }
    } finally {
      toggleCartOverlay();
      setIsSafeToAdd(true);
    }
  };

  return (
    <div
      onMouseOver={() => images.length > 1 && setIsHovered(true)}
      onMouseOut={() => images.length > 1 && setIsHovered(false)}
      className="item-banner border border-accent hover:cursor-pointer border-opacity-20 h-full !bg-white self-center flex flex-grow text-xl !rounded-md max-w-full place-items-center p-0 !m-0"
      onClick={() => navigateToProduct(glob, navigate)}
    >
      <div className="item-banner-inner !bg-white !p-0 relative">
        {/* Image Container with Fixed Height */}
        <div className="twaspect overflow-hidden !rounded-t-md !cursor-pointer relative">
          <img
            className={`twaspect w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-300 ease-in-out ${
              (isTransitioning) ? "opacity-0" : "opacity-100"
            }`}
            src={`http://localhost:8000/static/${images[activeImage]}`}
            alt={name[language] ?? name["en-gb"]}
          />
            <img
              className={`twaspect w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-300 ease-in-out ${
                isTransitioning ? "opacity-100" : "opacity-0"
              }`}
              src={`http://localhost:8000/static/${images[nextImage]}`}
              alt={name[language] ?? name["en-gb"]}
            />
        </div>

        <div className="xs:px-3 xs:!py-2 sm:px-4 sm:py-2 h-max flex !text-lg md:!px-6 md:!py-6 !flex-grow flex-col justify-end">
          <div className="flex flex-col-reverse nd:flex-row justify-between mb-1">
            <div className="item-banner-title !text-accent !font-semibold !text-lg !mt-1">
              {name[language] ?? name["en-gb"]}
            </div>
            <Rating reviews={reviews} intl={intl} />
          </div>
          <Price price={price} discount={discount} intl={intl} />
          <div className="item-banner-actions w-full hidden nd:flex !flex-0 !items-center justify-between nd:!justify-start md:w-full nd:w-4/5 mt-2 mb-2">
            <QuantitySelector count={count} setCount={setCount} stock={stock} />
            <ActionButton
              className="!text-first p-0.5"
              title=""
              disabled={count > stock || count < 1}
              onClick={(event) => {
                event.stopPropagation(); // Prevent propagation to parent div
                addToCart();
              }}
              icon={<ShoppingCart size={20} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withHandleResponse(injectIntl(ItemBanner));
