import React from "react";
import AdminScreenBase from "../components/AdminScreenBase";
import { ActionButton, ItemList, withDataFetching } from "src/components/index";
import { fetchOrders, updateOrder } from ".";
import { injectIntl } from "react-intl";
import messages from "./messages";

interface OrderProps {
  data: any[] | null;
  intl: any;
}

const Orders: React.FC<OrderProps> = ({ data, intl }) => {
  const [filter, setFilter] = React.useState({
    status: "all",
    country: "all",
  });
  const decideLogo = (country: string) => {
    switch (country) {
      case "Latvia":
        return "🇱🇻";
      case "Estonia":
        return "🇪🇪";
      case "Lithuania":
        return "🇱🇹";
      default:
        return "🌍";
    }
  };

  const filteredValues = data?.filter((order) => {
    if (filter.status === "all" && filter.country === "all") {
      return true;
    }
    if (filter.status === "all" && filter.country !== "all") {
      return order.delivery.country === filter.country;
    }
    if (filter.status !== "all" && filter.country === "all") {
      return order.status === filter.status;
    }
    return (
      order.status === filter.status &&
      order.delivery.country === filter.country
    );
  });

  return (
    <AdminScreenBase title="Orders">
      <div className="order-grid">
        <div className="order-header">
          <select
            value={filter.status}
            onChange={(event) =>
              setFilter({ ...filter, status: event.target.value })
            }
          >
            <option value="all">
              {intl.formatMessage(messages.filters.all)}
            </option>
            <option value="pending">
              {intl.formatMessage(messages.filters.pending)}
            </option>
            <option value="filled">
              {intl.formatMessage(messages.filters.filled)}
            </option>
            <option value="cancelled">
              {intl.formatMessage(messages.filters.cancelled)}
            </option>
          </select>

          <select
            value={filter.country}
            onChange={(event) => {
              setFilter({ ...filter, country: event.target.value });
            }}
          >
            <option value="all">
              {intl.formatMessage(messages.filters.all)}
            </option>
            <option value="Latvia">
              {intl.formatMessage(messages.filters.latvia)}
            </option>
            <option value="Estonia">
              {intl.formatMessage(messages.filters.estonia)}
            </option>
            <option value="Lithuania">
              {intl.formatMessage(messages.filters.lithuania)}
            </option>
          </select>
        </div>
        {filteredValues && filteredValues.length > 0 ? (
          filteredValues.map((order) => (
            <div className="order-container">
              <div className="order-id">{order._id}</div>
              <div className="order-client">
                <div className="order-client-name">
                  {order.customer.firstName}
                </div>
                <div className="order-client-name">
                  {order.customer.lastName}
                </div>
                <div className="order-client-email">{order.customer.email}</div>
              </div>
              <div className="order-status">
                <div className="order-current-status">{order.status}</div>
                <div className="order-total">{order.totalPrice}</div>
              </div>
              <div className="order-send-location">
                <div className="order-country">
                  {decideLogo(order.delivery.country)}
                </div>
                <div className="order-station">{order.delivery.station}</div>
              </div>
              <div className="order-actions">
                <ActionButton
                  title="Cancel order"
                  onClick={() => updateOrder(order._id, "cancelled")}
                />
                <ActionButton
                  title="Fill order"
                  onClick={() => updateOrder(order._id, "filled")}
                />
              </div>
            </div>
          ))
        ) : (
          <div> {intl.formatMessage(messages.info.no_orders)}</div>
        )}
      </div>
    </AdminScreenBase>
  );
};

export default withDataFetching(fetchOrders, "admin")(injectIntl(Orders));
