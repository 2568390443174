import { defineMessage, defineMessages } from "react-intl";
import globalMessages from "../../globalMessages";
import { merge } from "lodash";

const messages = {
  product: defineMessage({
    id: "product",
    defaultMessage: "{name}",
  }),

  text: defineMessages({
    product_stock: {
      id: "text.product_stock",
      defaultMessage: "in stock",
    },

    share_product: {
      id: "text.share_product",
      defaultMessage: "Share with others",
    },

    product_description: {
      id: "text.product_description",
      defaultMessage: "Description",
    },

    product_reviews: {
      id: "text.product_reviews",
      defaultMessage: "Reviews",
    },

    leave_review: {
      id: "text.leave_review",
      defaultMessage: "Leave a review",
    },
  }),

  buttons: defineMessages({
    add_to_cart: {
      id: "buttons.add_to_cart",
      defaultMessage: "Add to cart",
    },
    submit_review: {
      id: "buttons.submit_review",
      defaultMessage: "Submit",
    },
    view: {
      id: "buttons.view",
      defaultMessage: "View",
    },
  }),

  form: defineMessages({
    review: {
      id: "form.review",
      defaultMessage: "Review",
    },
    name: {
      id: "form.name",
      defaultMessage: "Name",
    },
    email: {
      id: "form.email",
      defaultMessage: "Email",
    },
  }),

  notifications: {
    review_success: defineMessage({
      id: "notifications.review_success",
      defaultMessage: "Review submitted successfully",
    }),
    add_to_cart: defineMessage({
      id: "notifications.add_to_cart",
      defaultMessage: "Product added to cart",
    }),
  },
};

export default merge({}, globalMessages, messages);
