import { defineMessages } from "react-intl";

const messages = {
  screen: defineMessages({
    title: {
      id: "shop.title",
      defaultMessage: "Shopping {category}",
    },
  }),
  no_items: defineMessages({
    title: {
      id: "shop.no_items",
      defaultMessage: "No items available",
    },
  }),
};

export default messages;
