import { defineMessage } from "react-intl";
import globalMessages from "../../globalMessages";
import { merge } from "lodash";

const messages = {
  payment_details: {
    processing: defineMessage({
      id: "payment_details.processing",
      defaultMessage: "Payment Details",
    }),
    pay_now: defineMessage({
      id: "payment_details.pay_now",
      defaultMessage: "Pay Now",
    }),
  },
};

export default merge({}, globalMessages, messages);
